@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "react-toastify/dist/ReactToastify.min.css";

* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  box-sizing: content-box !important;
}

.graph-heading-model {
  width: 100%;
  margin-top: 20px;
}

::-webkit-scrollbar {
  display: none;
}
.padding14px {
  padding: 14px !important;
}

.heading-bg-color-white {
  background-color: white !important;
}

.padding-heading {
  padding: 0px 25px;
}

button {
  border: none;
  background: transparent;
}

.height-73vh {
  height: 73vh !important;
}
.height-40vh {
  height: 40vh !important;
}

.whitespace-normal {
  white-space: normal;
  width: 120px;
}

:root {
  --green: #64da6430;
  --lightgreen: #43ff1e30;
  /* --neongreen: #3eff1887; */
  --blue: #2576bc30;
  --teal: #3dcdcd30;
  --yellow: #f7ba1e30;
  --red: #ff747c30;
  --white: #ffffff30;

  --heading: 1.12em;
  --subheading: 1em;
  --text: 0.85em;
  --label: 0.75em;

  --width5: 5vw;
  --width10: 10vw;
  --width15: 15vw;
  --width20: 20vw;
  --width25: 25vw;
  --width30: 30vw;
  --width35: 35vw;
  --width40: 40vw;
  --width45: 45vw;
  --width50: 50vw;
  --width55: 55vw;
  --width60: 60vw;
  --width65: 65vw;
  --width70: 70vw;
  --width75: 75vw;
  --width80: 80vw;
  --width85: 85vw;
  --width90: 90vw;
  --width95: 95vw;
  --width100: 100vw;

  --fw200: 200;
  --fw300: 300;
  --fw400: 400;
  --fw500: 500;
  --fw600: 600;
  --fw700: 700;

  --border-radius-5: 5px;
}

.br-5 {
  border-radius: var(--border-radius);
}

.width-5vw {
  width: var(--width5) !important;
}

.width-10vw {
  width: var(--width10) !important;
}

.text-center {
  text-align: center !important;
}

.width-15vw {
  width: var(--width15);
}

.width-20vw {
  width: var(--width20);
}

.width-25vw {
  width: var(--width25);
}

.width-30vw {
  width: var(--width30);
}

.width-35vw {
  width: var(--width35);
}

.width-40vw {
  width: var(--width40);
}

.width-45vw {
  width: var(--width45);
}

.width-50vw {
  width: var(--width50) !important;
}

.width-55vw {
  width: var(--width55);
}

.width-60vw {
  width: var(--width60);
}
.text-bold {
  font-weight: bold !important;
}

.width-65vw {
  width: var(--width65);
}

.width-70vw {
  width: var(--width70);
}

.width-75vw {
  width: var(--width75);
}

.width-80vw {
  width: var(--width80);
}

.width-85vw {
  width: var(--width85);
}

.width-90vw {
  width: var(--width90);
}

.width-95vw {
  width: var(--width95);
}

.width-100vw {
  width: var(--width100);
}

.font-size-heading {
  font-size: var(--heading);
}

.font-size-subheading {
  font-size: var(--subheading);
}

.font-size-text {
  font-size: var(--text);
}

.font-size-label {
  font-size: var(--label);
}

.font-weight200 {
  font-weight: var(--fw200);
}

.font-weight300 {
  font-weight: var(--fw300);
}

.margin {
  margin-bottom: 30px;
}

.font-weight400 {
  font-weight: var(--fw400);
}

.font-weight500 {
  font-weight: var(--fw500);
}

.font-weight600 {
  font-weight: var(--fw600);
}

.font-weight700 {
  font-weight: var(--fw700);
}

/* Day Status Color Code */
.behavior[data-status="present"],
.behavior[data-status="ontime"],
.behavior[data-status="wop"],
.behavior[data-status="rlm"],
.behavior[data-status="halfdayleave"],
.behavior[data-status="shortleave"],
.behavior[data-status="paidleave"] {
  background-color: var(--lightgreen);
}

.behavior[data-status="outduty"],
.behavior[data-status="hdoutduty"],
.behavior[data-status="shortoutduty"],
.behavior[data-status="workfromhome"],
.behavior[data-status="leave"] {
  background-color: var(--teal);
}

.behavior[data-status="late"],
.behavior[data-status="halfday"],
.behavior[data-status="unpaidleavehd"],
.behavior[data-status="unpaidshortleave"] {
  background-color: var(--yellow);
}

.behavior[data-status="misspunch"],
.behavior[data-status="unpaidleave"],
.behavior[data-status="absent"] {
  background-color: var(--red);
}

.behavior[data-status="weeklyoff"],
.behavior[data-status="holiday"] {
  background-color: var(--blue);
}

/* Attendance Status Color Code */
.behavior[data-status="green"],
.attendance-status[data-status="present"],
.attendance-status[data-status="ontime"],
.attendance-status[data-status="paidleave"],
.attendance-status[data-status="halfdayleave"],
.attendance-status[data-status="shortleave"],
.attendance-status[data-status="rlm"],
.attendance-status[data-status="wop"] {
  color: #06ad06;
}

.behavior[data-status="teal"],
.attendance-status[data-status="outduty"],
.attendance-status[data-status="hdoutduty"],
.attendance-status[data-status="shortoutduty"],
.attendance-status[data-status="workfromhome"] {
  color: #3dcdcd;
}

.behavior[data-status="red"],
.attendance-status[data-status="late"],
.attendance-status[data-status="halfday"],
.attendance-status[data-status="unpaidleavehd"],
.attendance-status[data-status="unpaidshortleave"],
.attendance-status[data-status="absent"],
.attendance-status[data-status="misspunch"],
.attendance-status[data-status="unpaidleave"] {
  color: #ff747c;
}

.behavior[data-status="yellow"],
.attendance-status[data-status="leave"] {
  color: #f7ba1e;
}

.behavior[data-status="blue"],
.attendance-status[data-status="weeklyoff"],
.attendance-status[data-status="holiday"] {
  color: #2576bc;
}

/* Reqyest Status Color Code */
.request-status[request-status="weeklyoff"] {
  color: #707070;
}

.request-status[request-status="approved"] {
  color: #06ad06;
}

.attendance-status[data-status="leave"],
.request-status[request-status="pending"] {
  color: #f7ba1e;
}

.request-status[request-status="rejected"] {
  color: #ff747c;
}

a {
  text-decoration: none !important;
}

/* ? ************* Form Styling Start ***************** */

.form-text-trasformation-uppercase {
  text-transform: capitalize;
}

.form-text-trasformation-alluppercase {
  text-transform: uppercase;
}

.notification {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dashboard-main-section {
  height: auto;
  display: flex;
  background-color: #fafcff;
}

.career-navbar {
  height: 150px;
  background: #fff;
  box-shadow: 0px 1px 38px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  padding: 0px 10vw;
  align-items: center;
}

.table-heading {
  background-color: #fff;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.paddingTop-0px {
  padding-top: 0px !important;
}

.table-box {
  height: 100%;
  /* max-height: 400px; */
  overflow-y: scroll;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-start {
  justify-content: start !important;
}

.justify-end {
  justify-content: end !important;
}

/* HierarchyChart.css */

.node {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 10px;
  margin: 10px;
  text-align: center;
}

/* .node-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.width-0vw {
  width: 10vw !important;
}

.vertical-heading {
  writing-mode: vertical-rl; /* Makes text vertical */
  transform: rotate(180deg); /* Optional: ensures text reads top-to-bottom */
  text-align: center; /* Centers the text */
  white-space: nowrap; /* Prevents text from breaking into multiple lines */
  padding: 10px; /* Adjust as necessary */
  min-width: 100px; /* Ensures proper width for the column */
}

.info {
  margin-bottom: 10px;
}

.children {
  display: flex;
  justify-content: space-around;
}

.salaryslip {
  width: 795px;
  height: 1125px;
  flex-shrink: 0;
  background: rgba(255, 252, 252, 0.95);
  position: absolute;
  padding: 10px;
}

.slaryslip-bg {
  top: 200px;
  left: 0px;
  width: 745px;
  height: 650.939px;
  transform: rotate(-13.776deg);
  flex-shrink: 0;
  opacity: 0.1;
  background: url("../public/images/frame-10000027082.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.salaryslip-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.salaryslip-logo {
  width: 200px;
  height: 100px;
  flex-shrink: 0;
  padding-top: 10px;
}

.upload-svga {
  background-color: #2576bc;
  color: var(--white);
  padding: 10px;
  margin: 10px 20px;
  border-radius: 10px;
  float: right;
  width: 24px;
  height: 25px;
}

.upload-svg {
  background-color: #2576bc;
  padding: 13px;
  margin: 10px 5px;
  border-radius: 10px;
  color: white;
}

.upload-svgg {
  background-color: #2576bc;
  padding: 1px;
  margin: 10px 5px;
  border-radius: 10px;
  color: white;
}

.update-button-size {
  margin: 10px 5px !important;
  padding: 13px !important;
}

.salaryslip-header-left-text {
  color: #2e72b9;
  padding: 10px 25px;
  position: absolute;
  width: 430px;
}

.salaryslip-header-right-top {
  color: #8f3134;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  z-index: 1;
  width: 440px;
  margin-bottom: 15px;
}

.salaryslip-header-right-bottom {
  text-align: right;
  color: #5a5656;
  line-height: normal;
  letter-spacing: -0.2px;
}

.salaryslip-hr {
  width: 795px;
  height: 1px;
  background: linear-gradient(
    270deg,
    rgba(152, 148, 148, 0) 0%,
    #989494 46.8%,
    rgba(152, 148, 148, 0) 100%
  );
  margin-top: 20px;
}

.salaryslip-hrr {
  width: 795px;
  height: 0.5px;
  background: linear-gradient(
    270deg,
    rgba(152, 148, 148, 0) 0%,
    #989494 46.8%,
    rgba(152, 148, 148, 0) 100%
  );
  margin-top: 20px;
}

.salaryslip-header-second {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.salaryslip-heading-black {
  color: #000;
  line-height: normal;
}

.salaryslip-heading-black1 {
  color: #000;
  line-height: normal;
}

.salaryslip-header-second-section3 {
  width: 1px;
  height: 96px;
  background: linear-gradient(
    180deg,
    rgba(152, 148, 148, 0) 0%,
    #989494 46.8%,
    rgba(152, 148, 148, 0) 100%
  );
}

.expense-header-second-section3 {
  width: 1px;
  height: 70px;
  background: linear-gradient(
    180deg,
    rgba(152, 148, 148, 0) 0%,
    #989494 46.8%,
    rgba(152, 148, 148, 0) 100%
  );
}

.salaryslip-header-third {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.salaryslip-heading-blue {
  color: #2d72b8;
}

.slaryslip-head {
  background: #f0e2e2;
  height: 40px;
}

.salaryslip-body {
  padding: 0px 15px;
}

.salary-table-td {
  text-align: right;
  padding: 5px 15px;
}

.salaryslip-bottom {
  float: left;
  margin-right: 40px;
}

.slaryslip-bg-signature {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 130px;
}

.expenseslip-head {
  background: #0089ff33;
  height: 40px;
}

.expense-table-td {
  text-align: center;
  padding: 5px 15px;
}

/* HierarchyChart.css */

a {
  color: #101010;
}

.preformance-div {
  width: 70%;
  max-width: 55rem !important;
  display: inline-block;
  text-align: justify;
  margin-left: 80px;
}

/*********************? Table Css !*************************/

.table-css {
  overflow: hidden;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-collapse: collapse;
  caption-side: top;
  border-radius: 16px;
}

.table-container {
  overflow: scroll;
}

.table-css-white-background {
  border-radius: 15px;
  background: #fff;
  padding: 15px;
  overflow: scroll;
  /* margin: 20px; */
}

.overflow-hidden {
  overflow: hidden !important;
}
.justify-end {
  justify-content: end !important;
}

.upload-button-field {
  width: 33vw;
  margin-right: 22px;
}

.table-css-white-backgrounds {
  border-radius: 15px;
  background: #fff;
  padding: 15px;
  overflow: scroll;
  margin: 20px;
}

.custom-table-width {
  overflow-x: auto;
  border-radius: 16px;
  background: #fff;
  padding: 15px 20px;
}

.table-heading-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-padding {
  padding: 33px 25px !important;
}

.table-action-flex {
  display: flex;
  justify-content: right;
  /* align-items: center; */
}

.custom-table-head-tr > th {
  padding: 30px 25px;
  color: #707070;
  font-size: var(--label);
  font-weight: var(--fw400);
}

.custom-table-head-tr-with-less-padding > th {
  padding: 0px 10px;
  color: #707070;
  font-size: var(--label);
  font-weight: var(--fw400);
}

.custom-table-head-th > th {
  /* padding: 10px 25px;
  color: #707070;
  font-size: var(--subheading);
  font-weight: var(--fw400); */
  color: #707070;
  font-weight: 600;
  padding: 30px 25px;
}

.custom-table-head-td > td {
  padding: 20px 25px;
  color: #101010;
  font-size: var(--label);
  font-weight: var(--fw500);
  border-top: 1px solid #e8e8e8;
}

.custom-table-head-td-with-less-padding > td {
  padding: 20px 15px;
  color: #101010;
  font-size: var(--label);
  font-weight: var(--fw500);
  border-top: 1px solid #e8e8e8;
}

.custom-table-head-td2 > td {
  width: 7%;
  padding: 15px 20px;
  color: #101010;
  font-size: var(--label);
  font-weight: var(--fw500);
  border-top: 1px solid #e8e8e8;
}

.custom-table-head-td3 > td {
  width: 7%;
  padding: 10px 20px;
  color: #101010;
  border-top: 1px solid #e8e8e8;
}

.custom-table-head-td4 > td {
  border-top: 1px solid #e8e8e8;
  padding: 30px 25px;
}

.padding-vertical-25px > td,
.padding-vertical-25px > th {
  padding: 0px 25px !important;
}

.align-remark {
  width: 10%;
}

.align-start {
  align-items: start !important;
}

.custom-table-container {
  position: static;
  max-width: 100%;
  border-radius: 16px;
  background: #fff;
  padding: 15px 10px;
}
.text-end {
  text-align: end !important;
}
.text-start {
  text-align: start !important;
}

.custom-table-head-tdd > td {
  padding: 20px 10px;
  color: #101010;
  border-top: 1px solid #e8e8e8;
}

.custom-table-subtable {
  overflow: hidden;
  width: 99%;
  padding: 0% 0.5%;
  background-color: #fff;
  border-collapse: separate;
  caption-side: top;
  border-spacing: 10px;
}

.summary-excel-tab {
  border-color: #e6e6e6;
  padding: 0px 5px;
  border-width: 1px;
  margin-left: 10px;
}

.summarysheet-heading-circle {
  background-color: #f2f2f2;
  text-align: center;
  border-radius: 50%;
}

.summarysheet-circle-default {
  background-color: #f2f2f2;
}

.summarysheet-circle-present {
  background-color: #f2f2f2;
}

.summarysheet-circle-absent {
  background-color: #f2f2f2;
}

.align-left {
  width: 40px;
  text-align: left;
  padding: 30px 15px !important;
}

.align-leftt {
  text-align: left;
}

.tr-border-bottom {
  background-color: #e8e8e8;
  margin: 0px 10px;
}

.repo-heading {
  color: #2576bc;
  font-size: 1.12em;
  font-weight: 500;
  margin-bottom: 20px;
}

.repo-heading-a {
  color: #101010;
  font-size: 1em;
  font-weight: 400;
  margin-left: 10px;
}

.heading-a:hover {
  color: #2576bc;
}

/*****************************Request management************************************/

.delete {
  width: 22px;
  height: 31px;
  padding-left: 12px;
  border-radius: 10px;
  background: #d4c3c2;
  margin: 5px;
}

.cutom-divider {
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
}

.flex-center {
  align-items: center !important;
}

.align-center {
  /* display: flex; */
  text-align: center;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 10px;
}

.align-right {
  text-align: right;
  justify-content: space-between;
}

.filter-showing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 5px;
}

.employee-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
  gap: 10px;
}

.employee-card {
  flex-basis: 32%;
  max-height: 167px;
  width: auto;
  flex-shrink: 0;
  border-radius: 16px;
  background: #fff;
  box-shadow: 4px 4px 20px 0px rgba(33, 33, 33, 0.05);
}

.employee-card-a {
  margin: 10px 10px 20px 10px;
  display: flex;
  justify-content: space-between;
}

.employee-card-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.employee-edit {
  background-color: #f6f7f9;
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
}

.employee-basic-details-edit {
  background-color: #d9f6f3;
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
}

.employee-details-edit {
  background-color: #f6f7f9;
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
}

.employee-edit-svg {
  padding: 10px;
}

.employee-detail-edit-svg {
  padding: 10px;
  color: #2576bc;
}

.employee-card-icons {
  margin-left: 10px;
}

.employee-card-image-background {
  width: 64px;
  height: 64px;
  background-color: #f2f2f2;
  border-radius: 16px;
}

.employee-card-imageee {
  width: 64px;
  height: 64px;
  border-radius: 16px;
}

.employee-card-name {
  color: #101010;
}

.employee-card-designation {
  color: #707070;
}

.employee-card-b {
  display: flex;
  margin: 10px;
  justify-content: space-between;
}

.employee-card-b-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.employee-card-b-heading {
  color: #101010;
  line-height: 20px;
}

.employee-card-b-text {
  color: #707070;
  line-height: 20px;
}

.interview-button {
  color: rgb(4, 241, 4);
  border: 1px rgb(4, 241, 4);
}

.accept {
  border-radius: 8px;
  border: 1px solid #f7f8fa;
  background: #f7f8fa;
  padding: 15px 20px;
  color: #707070;
}

.accept-rej {
  border-radius: 8px;
  border: 1px solid #f7f8fa;
  background: #f7f8fa;
  padding: 15px 20px;
  color: #df1d1d;
  margin-left: 20px;
}

.performance-button {
  height: 0%;
  width: 10%;
  margin-left: 10%;
}

.styled-table {
  overflow-x: auto;
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  text-align: left;
}

.styled-table thead tr {
  background-color: #2576bc;
  border-radius: 16px;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 12px;
  border: 1px solid #dddddd;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-radius: 16px;
  border-bottom: 2px solid #2576bc;
}

/* grey nested-table */
.nested-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  text-align: left;
}

.nested-table th,
.nested-table td {
  padding: 8px;
  border: 1px solid #dddddd;
}

.nested-table tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.nested-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

/* light blue nested-table */
.nested-table-lightblue {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  text-align: left;
}

.nested-table-lightblue th,
.nested-table-lightblue td {
  padding: 8px;
  border: 1px solid #dddddd;
}

.nested-table-lightblue thead tr:nth-of-type(odd) {
  background-color: #fff;
}

.nested-table-lightblue thead tr:nth-of-type(even) {
  background-color: #daefff;
}

.nested-table-lightblue tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.nested-table-lightblue tbody tr:nth-of-type(even) {
  background-color: #e9f5fd;
}

/* blue nested-table */
.nested-table-blue {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  text-align: left;
}

.nested-table-blue th,
.nested-table-blue td {
  padding: 8px;
  border: 1px solid #dddddd;
}

.nested-table-blue tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.nested-table-blue tbody tr:nth-of-type(even) {
  background-color: #cbe7fa;
}

.nested-table-blue thead tr:nth-of-type(odd) {
  background-color: #fff;
}

.nested-table-blue thead tr:nth-of-type(even) {
  background-color: #b4e0ff;
}

.model-annoucee {
  width: 78vw !important;
  max-width: 78vw !important;
  height: 85vh;
}

.model-annoucee-pic {
  width: 76vw !important;
  max-width: 76vw !important;
  height: 85vh;
}

.request-leave {
  width: 40vw !important;
  max-width: 40vw !important;
  min-width: 545px !important;
}

.request-leave-document {
  width: 80vw !important;
  max-width: 80vw !important;
  min-width: 545px !important;
}

.model-width90vw {
  width: 90vw !important;
  max-width: 90vw !important;
  min-width: 545px !important;
}

.model-width80vw {
  width: 80vw !important;
  max-width: 80vw !important;
  min-width: 545px !important;
}

.model-width60vw {
  width: 60vw !important;
  max-width: 60vw !important;
  min-width: 545px !important;
}

.modal-addwidth {
  width: 50vw !important;
  max-width: 50vw !important;
  min-width: 545px !important;
}

.custom-modal {
  /* width: 40vw !important; */
  max-width: 40vw !important;
  min-width: 545px !important;
}

.half-modal {
  max-width: 50vw !important;
  min-width: 545px !important;
}

.three-fourth-modal {
  max-width: 60vw !important;
  min-width: 545px !important;
}

.four-fifth-modal {
  max-width: 70vw !important;
  min-width: 545px !important;
}

.ourcompany-model {
  width: 80vw !important;
  max-width: 80vw !important;
  min-width: 545px !important;
}

.file-clear {
  position: relative;
  cursor: pointer;
}

.upload-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #0056b3;
}

a {
  text-decoration: none;
  cursor: pointer;
}

svg,
.sidebarname {
  cursor: pointer;
}

.dashboard-main-sidebar-section {
  min-width: 250px;
  width: 0px;
  padding: 10px;
}

.dashboard-main-sidebar-section-closed {
  text-align: center;
  height: calc(100vh - 20px);
  width: 80px;
}

.dashboard-main-content-sectionn {
  width: calc(100vw - 250px);
  background-color: #f4f7fe;
}

.dashboard-main-content-sectionnn {
  width: calc(100vw - 80px);
  background-color: #f4f7fe;
}

.dashboard-sidebar-middle-menu {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.dashboard-sidebar-middle-menu-closed {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}

.dashboard-sidebar-middle-menu-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.dashboard-sidebar-middle-menu-sublist {
  display: flex;
  align-items: center;
  text-align: left !important;
  padding-left: 5px;
}

.display-flex-style {
  margin-left: 25px;
}

.sidebar-expanded {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.sidebar-expanded-logo {
  width: 160px;
  height: 110px;
}

.sidebar-closed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-closed-logo {
  height: 60px;
  width: 60px;
  cursor: pointer;
}

.sidebar-closed-logo-cipl {
  height: 40px;
  width: 60px;
  cursor: pointer;
  margin-top: 10px;
}

.sidebar-title {
  margin: 15px 0px;
  color: #707070;
}

.sidebarname {
  color: #707070;
  text-align: left;
}

.tooltipp-container {
  position: relative;
}

.tooltipp {
  display: none;
  position: absolute;
  left: 70px;
  z-index: 99;
  background-color: #fff;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
  color: #2576bc;
  padding: 5px 10px;
  border-radius: 3px;
  transition: visibility 0.3s ease;
}

.tooltipp-container:hover .tooltipp {
  display: block;
}

.tooltipp-container:hover .tooltipp .tooltippp {
  display: block;
}

.attendance-primary-details {
  gap: 1rem;
}

.attendance-secondary-chart {
  width: 50%;
}

.primary-left,
.secondary-right {
  width: 40%;
  gap: 2.81rem;
  display: flex;
  flex-direction: column;
}

.items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.primary-left span:nth-child(1),
.secondary-right span:nth-child(1) {
  color: #707070;
}

.primary-left span:nth-child(2),
.secondary-right span:nth-child(2) {
  font-weight: var(--fw500);
}

.piechart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  border-radius: 10px;
  background-color: #fff;
  width: max-content;
  min-width: 340px;
}

.piechart-heading {
  display: flex;
  margin: 12px 0;
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  width: max-content;
}

.piechart-legend {
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: max-content;
}

.piechart-dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 10px;
}

.piechart-subheading {
  color: #707070;
}

.attendance-history-cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  margin: 20px 0px;
}

.attendance-heading {
  color: #2576bc;
  margin-bottom: 10px;
}

.date-uppercase {
  text-transform: uppercase;
  appearance: none;
}

.field-cont-between {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
}

.field-cont-modal {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  /* flex-wrap: wrap; */
}

.field-cont {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
}
.field-conts {
  display: flex;
  justify-content: flex-start;
  gap: 90px;
  align-items: center;
  flex-wrap: wrap;
}

.field-cont > hr {
  width: 2px;
  height: 18px;
  background: #707070b4;
}

.field-cont-div {
  display: flex;
  align-items: center;
}

.field-cont-div-svg {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.field-cont-hr {
  margin: 0px 10px;
  width: 1.5px;
  height: 40px;
  background: #707070b4;
}

.date-field::-webkit-calendar-picker-indicator {
  filter: invert(50%);
  cursor: pointer;
  left: 9.5rem;
  width: 20px;
  height: 20px;
}

.status-row {
  cursor: pointer;
  color: #05cd99;
}

.date-field::-webkit-datetime-edit {
  padding: 0 0.5rem;
}

.date-field::-webkit-datetime-edit-month-field,
.date-field::-webkit-datetime-edit-day-field,
.date-field::-webkit-datetime-edit-year-field {
  text-transform: uppercase;
}

/* ? ********** Login Start ********** */

.login-page {
  display: flex;
  justify-content: space-evenly;
  height: 100vh;
  align-items: center;
}

.login-page-first-heading {
  color: #101010;
  font-size: 2.5em;
}

.login-page-second-heading {
  color: #101010;
  font-size: 3.1em;
}

.login-page-third-heading {
  color: #707070;
  line-height: 28px;
  margin-bottom: 5vh;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.expense-req {
  display: flex;
  width: 18vw;
}

.flex-row {
  display: flex;
  align-content: center;
  align-items: center;
}

.form-labels {
  color: #101010;
  margin-top: 10px;
}

.form-labels-grey {
  color: #696969;
}

.img-form {
  height: 70px;
}

.img-modal-table {
  height: 100px;
  border-radius: 10%;
}

.img-table {
  height: 50px;
  width: 50px;
  border-radius: 20%;
}

.profile-img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: px;
}

.letter-heading {
  margin-bottom: 0px !important;
  padding-left: 15px !important;
}

.file-name {
  width: 10vw;
  margin-top: 9px;
  margin-left: 5px;
}

.form-input,
.form-textarea {
  width: 33vw;
  height: 50px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #e3e3e3;
  margin: 10px 0px 20px 0px;
  color: #4a4a4a;
  background: #f7f8fa;
  padding: 0px 10px;
}

.upload-logo {
  height: 50px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #e3e3e3;
  margin: 10px 0px 20px 0px;

  padding: 0px 10px;
}

.form-input-date {
  width: 22vw;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 10px 0px 20px 0px;
  color: #4a4a4a;
  background: #f7f8fa;
  padding: 0px 10px;
}

.form-input-time {
  width: 15vw !important;
  /* height: 50px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 10px 0px 20px 0px;
  color: #4a4a4a;
  border: 0px;
  background: #f7f8fa;
  padding: 0px 10px; */
}

.input-checkbox {
  margin: 8px 0px 0px 10px;
}

.filter-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  color: #4a4a4a;
  padding: 0px 10px;
}

.form-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 10px 0px 20px 0px;
  color: #4a4a4a;
  padding: 0px 10px;
  margin: 10px 0px 0px 20px;
}

.inputform-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 10px 0px 20px 0px;
  color: #4a4a4a;
  padding: 0px 10px;
  margin: 10px 0px 0px 20px;
}

.form-table-checkbox {
  width: 1vw;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  /* margin: 10px 0px 20px 0px; */
  color: #4a4a4a;
  padding: 0px 10px;
  /* margin: 10px 0px 0px 20px; */
}

.form-input-textarea {
  width: 33vw;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 10px 0px 20px 0px;
  color: #4a4a4a;
  border: 0px;
  background: #f7f8fa;
  padding: 10px 10px;
}

.form-input-quill {
  width: 33vw;
  /* height: 100px; */
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 10px 0px 20px 0px;
  color: #4a4a4a;
  border: 0px;
  background: #f7f8fa;
  padding: 10px 10px;
}

.task-tab-complete {
  padding-top: 10px;
}

.task-tab-close {
  padding-bottom: 10px;
}

.form-modal-input {
  max-width: 10vw;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 10px 8px 22px 3px;
  color: #4a4a4a;
  border: 2px solid blue;
  padding: 10px 10px;
}

.form-inputs {
  width: 6vw;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 10px 8px 22px 3px;
  color: #4a4a4a;
  /* border: 2px solid blue; */
  padding: 10px 10px;
}

.pdf-input {
  width: 2vw;
  /* height: 18px; */
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 0px 0px 0px 0px;
  color: blue;
  border: 2px solid blue;
  padding: 10px 10px;
}
.height25px {
  height: 25px !important;
}
.clear-button {
  width: 5vw;
  color: red;
  border-color: #2576bc;
  border-width: 36px;
  padding: 10px 0px;
  border-radius: 30px;
  border: 3px solid red;
  background: #f7f8fa;
  /* color: #707070; */
  font-size: 1.1em;
}

.svg-icon {
  width: 0px !important;

  padding: 10px 5px;
}

.svg-field {
  width: 5vw;
  height: 25px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 0px 0px 0px 0px;
  color: blue;
  border: 2px solid blue;
  padding: 10px 10px;
}

.form-inputss {
  width: 7vw;
  height: 25px;
  border-radius: 10px;
  margin: 4px 22px 0px 30px;
  color: #4a4a4a;
  border: 0px;
  padding: 5px 5px;
}

.action-div {
  margin-top: 15px;
}

.form-inputs-submit {
  height: 25px;
  min-width: min-content;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 0px 0px 0px 10px;
  color: white;
  border: 0px;
  background: #2576bc;
  padding: 5px 5px;
}

.form-input-textarea::placeholder {
  color: #707070;
}

.label-sub-heading {
  color: #707070;
}

.form-input-background {
  background: #f7f8fa;
  border-color: #fff !important;
}

.form-input::placeholder {
  color: #707070;
  font-size: var(--subheading);
  font-weight: var(--fw500);
}

.form-group-select {
  position: relative;
  display: inline-block;
  /* margin-right: 20px; */
}

.form-group-selectt {
  position: relative;
  display: inline-block;
}

.form-group-selection {
  width: 33vw;
  height: 50px;
  border-radius: 10px;
  margin: 10px 0px 20px 0px;
  color: #4a4a4a;
  padding: 0px 10px;
  appearance: none;
  cursor: pointer;
}

.form-group-selection-modal {
  width: 33vw;
  height: 40px;
  border-radius: 10px;
  margin: 10px 0px 20px 0px;
  color: #4a4a4a;
  padding: 0px 10px;
  appearance: none;
  cursor: pointer;
}

.form-group-selection-arrow {
  position: absolute;
  top: 60%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 1.1em;
  color: var(--button);
}

.error-message {
  color: red;
  margin-top: -10px;
  margin-bottom: 10px;
}

.success {
  border-color: green !important;
}

.error {
  border-color: red;
}

.forgotbutton {
  /* width: 34vw; */
  color: #2576bc;
  /* text-align: right; */
  opacity: 0.8;
  float: right;
  padding-right: 10px;
  margin-top: 10px;
}

.login-button {
  margin-top: 20px;
  width: 32.5vw;
  padding: 15px;
  color: #fff;
  border-radius: 7px;
  background: #2576bc;
  box-shadow: 0px 15px 38px 0px rgba(37, 118, 188, 0.15);
}

.theading > th,
.nested-heading,
.tbody > td {
  font-size: 0.8em !important;
}

.remove-button {
  margin-top: 40px;
  padding: 10px 30px;
  border-radius: 7px;
  background: #f7f8fa;
  color: #707070;
  border: 1px solid #f7f8fa;
}

.button-models {
  display: flex !important;
  justify-content: space-evenly !important;
  margin: 15px !important;
}

.model-button {
  padding: 15px 40px;
  border-radius: 30px;
  border: 1px solid #f7f8fa;
  background: #f7f8fa;
  color: #707070;

  font-size: 1.1em;
}
.modal-padding-heading th {
  padding: 30px !important;
}

.table-heading-text,
.table-body {
  text-align: left;
  /* text-transform: capitalize; */
  white-space: nowrap;
  vertical-align: text-top !important;
}

.model-button-delete {
  padding: 12px 40px !important;
  color: #f72b50;
  border-color: #f72b50;
  border-width: 3px;
}

.model-button-pending {
  padding: 12px 40px !important;
  color: rgb(247, 186, 30);
  border-color: rgb(247, 186, 30);
  border-width: 3px;
}

.model-button-submit {
  border: 1px solid #2576bc;
  background: #2576bc;
  color: #fff;
}

.attendance-calander {
  text-transform: capitalize;
  background-color: #fff;
}

.attendance-status {
  text-transform: capitalize;
  background-color: #fff;
}

/* ! repeatitive */
.attendance-calendar-container {
  padding: 25px;
  border-radius: 10px;
  background-color: #fff;
  width: 45vw;
}

.calendar {
  width: 45vw;
}

.attendance-calander-heading {
  color: #696969;
}

.attendance-calander-heading-td {
  padding: 20px 2vw;
  text-align: center;
}

.attendance-calander-span {
  color: #707070;
}

.piechartt {
  padding: 12px 27.5px;
  border-radius: 10px;
  background-color: #fff;
  width: 39vw;
  min-width: 340px;
}

.hrdb-piechart-dashboard {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #fff;
  width: 45vw;
  min-height: 275px;
}

.hrdb-piechart-dashboard-heading {
  display: flex;
  justify-content: space-between;
}

.radialchart-dropdown,
.paymentDashboard-dropdown {
  border: none;
}

.piechart-dashboard {
  display: flex;
  flex-direction: column;
  width: 130px;
  justify-items: center;
  text-align: center;
  /* Center align text */
}

.piechart-percentage {
  font-size: var(--text);
  color: #101010;
  text-align: center;
}

.piechart-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Allow flex items to wrap */
}

.hr-piechart-dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.hr-piechart-heading {
  display: flex;
  width: 220px;
  margin: 10px 0px;
  justify-content: center;
  align-items: center;
}

.hr-piechart-dashboard {
  display: flex;
  flex-direction: column;
}

.hr-piechart-subheading {
  color: #707070;
}

.dashboard-designs {
  padding: 0px 5px;
  background-color: #f4f7fe;
}

.financebarchart {
  height: 100%;
  width: 100%;
  justify-content: center;
}

.barchart-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dashboard-designs-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 1vw;
}

.hr-dashboard-designs-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5vw;
}

.dashboard-designs-column {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 1vw;
}

.dashboard-designs-right-flex {
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}

.dashboard-designs-secondary-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5vw;
  margin-top: 20px;
}

.dashboard-designs-left-flex {
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}

.expense-circle-outer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.expsense-circle {
  display: flex;
  width: 27px;
  height: 27px;
  margin: 2px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
}

.expsense-circle-general {
  background: #f2f2f2;
}

.expsense-circle-used {
  background: #ee5d5033;
}

.expsense-circle-today {
  background: #2576bc;
  color: white;
}

.expsense-circle-selected {
  background: #2576bc33;
}

.dashboardcomponent {
  width: 43vw;
  height: 350px;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
}

.active-tabs {
  border-bottom: 2px solid #2576bc;
  color: #2576bc;
}

.bloc-tabs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  /* overflow-x: scroll; */
}

.bloc-tabs-apply {
  padding: 0px 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.bloc-tabs-apply4 {
  padding: 0px 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.bloc-tabs-task {
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: scroll;
}

.bloc-tabss {
  padding: 15px 0px !important;
}

.bloc-tabss2 {
  padding: 15px 0px !important;
  margin: auto;
}

.bloc-tabs-with-white-bg {
  display: flex;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 16px;
  margin-bottom: 10px;
  overflow: scroll;
}

.bloc-tabs-with-white-bg > button {
  margin: 0px 20px;
  padding: 5px 10px;
}

.bloc-tabs-with-lesswhite-bg > button {
  margin: 0px 20px 0px 0px;
  padding: 5px 10px;
}

.wfm-margin {
  margin-left: 20px;
  display: flex;
  overflow: scroll;
}

.wfm-without-margin {
  display: flex;
  overflow: scroll;
}

.wfm-marginn {
  margin-left: 30px;
  display: flex;
  overflow: scroll;
}

.wfm-margin > button,
.wfm-marginn > button {
  margin-right: 20px;
}

.dashboardcomponent-tabss {
  background-color: #f4f7fe;
}

.dashboardcomponent-tabs {
  cursor: pointer;
  padding: 5px;
  margin-right: 5px;
  position: relative;
  color: #707070;
}

/* .dashboardcomponent-tabs::after,
.bloc-tabs::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f7f8fa;
  border-radius: 10px;
} */
/* th{
  color:#707070;
} */
.white-tab::after {
  background-color: #ffffff;
}

.dashboardcomponent-tabs:hover {
  position: relative;
  color: #2576bc;
}

.white-tab:hover {
  background-color: #ffffff;
}

.dashboardcomponent-active-tabs {
  position: relative;
  color: #2576bc;
}

.dashboardcomponent-active-tabs::after,
.dashboardcomponent-tabs:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 2px;
  background-color: #2576bc;
  border-radius: 10px;
}

.content-tabs {
  margin: 20px;
}

.content-tabss {
  flex-grow: 1;
  margin: 0px 20px;
  background: #f7f8fa;
}

.backgroundmain-task {
  height: 100%;
  display: flex;
  align-items: center;
  background: #f4f7fe;
  justify-content: flex-start;
}

.dashboardcomponent-task {
  min-width: 340px;
  height: 350px;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
}

.navbar-logo-mobile {
  display: flex;
  align-items: center;
}

.recharts-default-legend {
  justify-content: flex-end;
}

.balance-cont {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
}

.bgwhite {
  background: var(--white);
}

.model-age {
  margin: 10px 0px 20px 0px;
}

.age-label {
  margin-left: 8px;
  color: #707070;
}

.leaves {
  margin-bottom: 7px;
}

.bloc-tabs-lb {
  padding: 5px;
  margin-right: 15px;
  position: relative;
  color: pink;
}

.leave-duration {
  color: #101010;
}

.leave-duration-heading {
  color: #101010;
  margin-bottom: 18px;
}

.single-day-date {
  margin-bottom: 20px;
}

.single-day-date-input {
  width: 33vw;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin: 10px 0px 20px 0px;
  color: #4a4a4a;
  padding: 0px 10px;
}

.multi-day-input {
  display: flex;
  justify-content: space-between;
  width: 38vw;
}

.multi-day-input1 {
  width: 14vw;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin-top: 15px;
  color: #707070;
  padding: 0px 10px;
}

.policy-input {
  display: flex;
  justify-content: space-between;
}

.policy-input1 {
  width: 14vw;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  margin-top: 15px;
  color: #707070;
  padding: 0px 10px;
}

.multi-day {
  width: 55%;
}

.model-add {
  padding: 10px;
}

.half-day-radio {
  width: 70%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.half-day-radio1 {
  margin-right: 10px;
}

.model-button-leave {
  padding: 15px 40px !important;
  color: white;
  background-color: #2576bc;
}

.model-button-print {
  border-radius: 10px;
  padding: 10px 13px !important;
  color: white;
  background-color: #2576bc;
}

.model-button-download {
  padding: 15px 30px !important;
  color: white;
  background-color: #2576bc;
}

.model-button-cancel {
  padding: 12px 40px !important;
  color: #2576bc;
  border-color: #2576bc;
  border-width: 3px;
}

.model-button-black {
  padding: 12px 40px !important;
  color: black;
  border-color: black;
  border-width: 3px;
}

.leave-radio-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.task-heading {
  padding: 10px;
}

.task-sub-heading {
  color: #101010;
  margin-bottom: 10px;
}

.dashcomp-announcement-heading {
  color: #101010;
}

.dashcomp-announcement-date {
  color: #707070;
  align-items: center;
}

.dashcomp-announcement-date-svg {
  margin-right: 10px;
}

.dashcomp-announcement-p {
  color: #101010;
  margin: 5px 0px;
}

.EmployeeDashboad-notification-card {
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.dashcomp-task-cards {
  overflow-x: hidden;
  /* overflow-y: visible !important; */
  height: 250px;
}

.dashcomp-task-card-data {
  margin-bottom: 20px;
}

.EmployeeDashboad-notification-cards {
  overflow-x: hidden;
  /* overflow-y: visible !important; */
  height: 300px;
}

.EmployeeDashboad-notification-cards-wrap {
  display: flex;
  flex-wrap: wrap;
}

.dashcomp-bg-card {
  margin: 20px 15px;
  width: 100%;
  padding: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
}

.dashcomp-bg-card-style {
  margin: 20px 15px;
  width: 150px;
  padding: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
}

.dashcomp-bg-card-styling {
  margin: 10px 20px;
  width: 180px;
}

.birthdaywrap {
  display: flex;
  flex-wrap: wrap;
}

.birthdaywrap-card {
  margin: 10px 15px;
  width: 180px;
  /* text-align: center; */
}

.dashcomp-image {
  height: 100px;
  border-radius: 50%;
}

/* 
.dashcomp-image-mod {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
} */

.dashcomp-event-image {
  height: 100px;
  width: 200px;
  border-radius: 20%;
}

.dashcomp-event-image2 {
  height: 110px;
  width: 110px;
  border-radius: 20%;
}

.dashcomp-bgimagee {
  height: 600px;
}

.task-hr {
  margin-bottom: 10px;
  width: 100%;
  height: 2px;
  background-color: #f7f8fa;
  border-radius: 10px;
}

.calander-height {
  height: 44px;
}

/*******************************Announcement********************************/
.models-button {
  border-radius: 10px;
  margin: 10px 10px;
  background: #f6f7f9;
}

.model-add-button {
  border-radius: 50%;
  background: #d9ecf6;
  padding: 10px;
  margin-right: 10px;
}

.model-edit-button {
  padding: 10px;
  border-radius: 50%;
  background-color: #d9f6f3;
  margin-right: 10px;
}

.model-partial-button {
  padding: 10px;
  border-radius: 50%;
  background-color: #f6f0d9;
  margin-right: 10px;
}

.model-delete-button {
  padding: 10px;
  border-radius: 50%;
  background-color: #fee6ea;
}

.model-view-button {
  border-radius: 10px;
  background: #d9ecf6;
  margin: 18px;
}

.model-hue-button {
  border-radius: 10px;
  background: #d9ecf6;
}

.parent-div {
  background-color: #fff;
}

.bdy-div {
  padding: 20px;
}

.announce-date {
  color: #101010;
}

.notify_modal_parent_div {
  border-radius: 50%;
}

.notify_modal_body_div {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.notify_modal_body_div_small {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.custom-notify-modal .modal-content {
  border-radius: 25px;
}

.notify_modal_logo {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-content: center;
  justify-self: center;
  margin-right: 20px;
}

.notify_modal_content {
  color: #101010;
}

.success_color {
  background-color: #53ab3a;
}

.success_color_text {
  color: #53ab3a;
}

.neutral_color {
  background-color: #61616a;
}

.neutral_color_text {
  color: #61616a;
}

.alert_color {
  background-color: #f6c452;
}

.alert_color_text {
  color: #f6c452;
}

.info_color {
  background-color: #3c74e0;
}

.info_color_text {
  color: #3c74e0;
}

.error_color {
  background-color: #df5d4b;
}

.error_color_text {
  color: #df5d4b;
}

.table-search {
  display: flex;
  align-items: center;
  background-color: #f6f7f9;

  border-radius: 11px;
  padding: 5px 20px;
  height: 40px;
}

.tab-search-long {
  width: 85%;
  display: flex;
  align-items: center;
  background-color: #f6f7f9;

  border-radius: 11px;
  padding: 5px 20px;
  height: 40px;
}

.table-searchh {
  display: flex;
  align-items: center;
  background-color: #f6f7f9;
  border-radius: 11px;
  padding: 5px 20px;
  height: 40px;
  margin-left: 90px;
}

.navbar-inputt {
  border: none;
  background-color: #f6f7f9;
  outline: none;
  margin-left: 10px;
}

.navbar-input {
  border: none;
  background-color: #f4f7fe;
  outline: none;
  margin-left: 10px;
}

.navbar-inputt::placeholder {
  color: #727272;
  font-size: var(--text);
  font-weight: var(--fw400);
  line-height: 20px;
  letter-spacing: -0.28px;
  border: none;
  padding: 10px 15px;
}

.navbar-input::placeholder {
  color: #8f9bba;
  font-size: var(--text);
  font-weight: var(--fw400);
  line-height: 20px;
  letter-spacing: -0.28px;
  border: none;
  padding: 10px 15px;
}

.dropdown-container {
  margin: 10px 5px;
}

.dropdown {
  background-color: #f6f7f9;
  border: none;
  border-radius: 11px;
  padding: 5px 10px;
  height: 40px;
  outline: none;
  /* color: #2b3674; */
}

.dropdown-wh {
  background-color: #ffffff;
  border: none;
  border-radius: 11px;
  padding: 0px 5px;
  height: 40px;
  outline: none;
  align-items: center;
  /* color: #2b3674; */
}

.navbar-svg {
  padding: 10px;
}

.navbar-user-login {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.primary-left-chart {
  width: 51%;
}

.empDataa {
  padding: 10px 25px;
  border-radius: 10px;
  background-color: #fff;
  width: 88.5vw;
}

.taskstats {
  padding: 10px 25px;
  border-radius: 10px;
  background-color: #fff;
  /* width: 35vw; */
  width: 95%;
  /* height: 200px; */
  min-width: 340px;
}

.empData-felx {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.empData-felx2 {
  /* margin: 20px 70px; */
  display: flex;
  flex-wrap: wrap;
  /* gap: 40px; */
  align-items: center;
}

.empData-dashboard {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.empData-tab {
  border-radius: 10px;
  min-width: 150px;
  padding: 15px 15px;
  height: 120px;
}

.task-tab {
  border-radius: 10px;
  min-width: 150px;
  padding: 15px 15px;
  height: 120px;
}

.Color-tab-red {
  background-color: #ff747c30;
  border: 1px solid #ff747ccc;
}

.Color-tab-yellow {
  background-color: #f7ba1e30;
  border: 1px solid #f7ba1ecc;
}

.Color-tab-green {
  background-color: #06ad0630;
  border: 1px solid #06ad06cc;
}

.Color-tab-blue {
  background-color: #2576bc30;
  border: 1px solid #2576bccc;
}

.Color-tab-teal {
  background-color: #3dcdcd30;
  border: 1px solid #3dcdcdcc;
}

.Color-tab-grey {
  background-color: #f2f2f3;
  border: 1px solid #61616a;
}

.empData-Tab-heading {
  margin: 10px 0px;
}

.task-Tab-heading {
  margin: 10px 0px;
}

.empData-Tab-value {
  color: black;
  font-size: 1.87em;
  margin-top: 15px;
}

.task-Tab-value {
  color: #2576bc;
  font-size: 1.87em;
  margin-top: 15px;
}

.radialchart {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  border-radius: 10px;
  background-color: #fff;
  width: max-content;
  min-width: 340px;
}

.radialchart-legend {
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: max-content;
}

.radialchart-dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 10px;
}

.radialchart-subheading {
  color: #707070;
}

/* <=================== User Details Styling ===================> */

.form-label {
  margin-top: 30px;
}

/* Attendance Styling */

.attendance-top-cont {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 16px;
}

.attendance-primary-details,
.attendance-secondary-chart {
  width: 40%;
  display: flex;
  justify-content: space-between;
  padding: 30px 40px;
  background: #ffffff;
  border-radius: 16px;
}

.attendance-subcont {
  display: flex;
  padding: 15px 30px;
  align-items: center;
  border-radius: 16px;
  background: #ffffff;
  margin-bottom: 20px;
  justify-content: space-between;
}

.filter-outer-box {
  display: flex;
  padding: 15px 30px;
  align-items: center;
  border-radius: 16px;
  background: #ffffff;
  margin: 10px 0px;
  justify-content: space-between;
}

.attendance-subcontainer {
  display: flex;
  padding: 15px 30px;
  align-items: center;
  border-radius: 16px;
  background: #ffffff;
  /* margin-bottom: 20px; */
  justify-content: space-between;
}

.field-container {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}

.field-cont > svg {
  width: 17px;
  height: 17px;
  margin-left: 10px;
}

.attendance-input-field {
  color: #707070;
  outline: none;
  border: none;
  padding: 0.6rem 0.5rem;
  text-transform: capitalize;
}

.attendance-input-fieldd {
  color: #707070;
  outline: none;
  border: none;
  padding: 0.6rem 0.5rem;
  text-transform: capitalize;
}

.input-color-light {
  margin: 0px 5px;
  color: #707070;
}

.btn-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.attendance-btn {
  padding: 14px 26px;
  background: #2576bc;
  color: #ffffff;
  border-radius: 8px;
  gap: 1rem;
  display: flex;
  align-items: center;
}

.searchicon {
  filter: invert(100%);
  cursor: pointer;
}

/* Leave Styling */

.profile-leave-primary {
  background-color: white;
  width: 45%;
  padding: 20px;
  border-radius: 15px;
}

.profile-leave-status {
  display: flex;
  justify-content: space-between;
  margin: 14px 20px;
}

.profile-leave-status-heading {
  color: rgb(94, 94, 94);
}

.profile-leave-secondary {
  width: 45%;
}

/* =======> Leave Chart <=========== */
.leavechart-heading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* ==========> Profile Leave Section <======== */
.profile-leave-section {
  gap: 16px;
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #f7f8fa;
}

.leave-tab {
  margin-right: 30px;
}

/* Document Styling */
.document-grid {
  gap: 18px;
  display: flex;
  padding: 30px 0;
  flex-wrap: wrap;
  align-items: center;
  background: #ffffff;
  justify-content: center;
  width: 320px;
}

.document-grid1 {
  gap: 18px;
  display: flex;
  padding: 30px 0;
  flex-wrap: wrap;
  align-items: center;
  background: #ffffff;
  justify-content: center;
}

.document-grid2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  background: #ffffff;
}

.document-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10px;
  padding: 30px 15px;
  border-radius: 15px;
  width: 300px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.document-item1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10px;
  padding: 30px 15px;
  border-radius: 15px;
  width: 350px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.leave-radio-button-inner-div {
  margin: 0px 1vw 0px 0px;
}

/*******************************Announcement********************************/

.model-update-button {
  padding: 10px;
  border-radius: 50%;
  background: #d9ecf6;
  margin-right: 10px;
}

/* .announce-heading {
  color: #101010;
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
} */

.amount-status {
  display: flex;
  justify-content: center;
}

.utilize {
  color: #707070;
}

.remaining {
  padding-left: 100px;
  color: #707070;
}

/* .remaining-amount {
  padding-left: 100px;
  text-align: center;
  color: #2476bd;
  font-weight: 500;
} */

.line {
  width: 100%;
  height: 1px;
  background-color: rgb(167, 161, 161);
  border: none;
}

.priority-button {
  padding: 8px 15px;
  border-radius: 25px;
}

.priority-button2 {
  padding: 0px 10px;
  border-radius: 25px;
}

.document-name-div {
  display: flex;
}

.document-upload-date {
  color: gray;
}

.document-icons {
  display: flex;
  width: 100%;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
}

.customHeightFix {
  height: 200px;
}

.document-iconss {
  display: flex;
  height: 200px;
  width: 100%;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
}

.document-icon {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.document-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.document-download-button:hover {
  transform: scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.2) 2px 5px 20px, rgba(0, 0, 0, 0.22) 0px 1px 1px;
}

.document-download-button {
  height: 40px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  background-color: #2476bd;
  /* box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1); */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 100ms ease-in-out;
}

/* Finance Styling */
.finance-primary-chart {
  width: 40%;
}

.finance-secondary-details {
  gap: 1rem;
  width: 60%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.salary-structure {
  color: #2576bc;
}

.salary-info-container {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 16px;
  background: #fff;
  padding: 30px 2vw 20px 3vw;
  margin: 0 0 10px 0;
}

.salary-graph {
  width: 35%;
}

.financechart {
  height: 86% !important;
}

.financebarchart-cont {
  width: 92%;
  gap: 55px;
  padding: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  background: #ffffff;
  flex-direction: column;
}

.financebarchart-maincont {
  margin: 0 0 0 10px;
}

.finance-details-cont {
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.fi-cards {
  gap: 0.6rem;
  display: flex;
  flex: 0 1 42%;
  min-height: 7.5rem;
  padding: 15px 20px;
  border-radius: 10px;
  background: #f7f8fa;
  flex-direction: column;
}

.finance-heading {
  color: #707070;
}

.finance-subheading {
  color: #2576bc;
}

/* Error404 */
.finance-chart-main-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.finance-chart-main-cont img {
  width: 20rem;
  height: 20rem;
}

/* Expense Styling */
.expense-bloc-tabs {
  overflow: auto;
  display: flex;
  /* flex-wrap: wrap; */
  padding: 10px;
  background: #ffffff;
  align-items: center;
  border-radius: 16px;
}

.document-content-tab {
  padding: 10px;
  background: #ffffff;
  align-items: center;
  border-radius: 0 0 16px 16px;
}

.expense-bloc-tabs > button {
  margin: 0px 20px;
  padding: 5px 10px;
}

.expense-active-tab,
.expense-inactive-tab {
  background: #ffffff !important;
}

.expense-inactive-tab::after {
  content: "";
  background: #fff !important;
}

.left-expense-bloc-tab,
.right-expense-bloc-tab {
  width: 50%;
  display: flex;
  align-items: center;
}

.left-expense-bloc-tab {
  gap: 2rem;
}

.right-expense-bloc-tab {
  justify-content: flex-end;
}

.right-expense-bloc-tab img,
.expense-table-cont img {
  cursor: pointer;
}

/* Performance Styling */
.awards-main-cont {
  gap: 2rem;
  display: flex;
  margin: 1rem 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

/* Performance Styling */
.performance-table-cont {
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  padding: 20px 30px;
  background: #ffffff;
}

.awards-card {
  gap: 2rem;
  min-width: 350px;
  display: flex;
  margin-bottom: 10px;
  border-radius: 16px;
  padding: 1.5rem 1rem;
  background: #f7f8fa;
  flex-direction: column;
}

.primary-subcard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secondary-subcard {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.top-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.award-title {
  width: max-content;
  background: #f7f8fa;
  padding: 0 0.5rem;
  text-align: center;
}

.hr-sep {
  width: 20%;
  height: 2px;
  background-color: #2576bc;
}

.middle-details {
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.awards-cards-heading {
  color: #707070;
}

.bottom-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bottom-subcards {
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.bottom-subcards:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.performance-profile-img {
  height: 80px;
  border-radius: 93px;
  border: 3px solid #2576bc;
}

.performance-reward-img {
  height: 70px;
}

/* <=================== END of User Details Styling ===================> */

.jd-timeline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.jd {
  margin: 5vh 10vw;
}

.jd-timeline-border {
  width: 10vw;
  margin-top: -45px;
  border-bottom: 5px dashed #707070;
}

.jd-timeline-borderr {
  margin-top: -45px;
  border-bottom: 5px dashed #707070;
}

.timeline-color {
  color: #2576bc;
  border-color: #2576bc;
}

.jd-timeline-svg-heading {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.jd-bloc-tabs {
  display: flex;
  padding: 0px 10px;
  background: #ffffff;
  align-items: center;
  border-radius: 16px 16px 0 0;
  justify-content: space-between;
}

.jd-heading-outer {
  margin-top: 30px;
  margin-bottom: 30px;
}

.jd-heading-outer-flex {
  display: flex;
  justify-content: space-between;
}

.jd-heading-main {
  color: #2576bc;
  line-height: 42px;
}

.jd-heading-main-right-button {
  border-radius: 10px;
  border: 1px solid #cfd0d7;
  background: #f7f8fa;
  padding: 10px;
}

/* .jd-heading-main-right {
  color: #707070;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.014px;
} */

.jd-heading-bottom-bold {
  width: 104px;
  height: 4px;
  flex-shrink: 0;
  background: #2576bc;
  box-shadow: 0px 0px 4px 0px rgba(27, 37, 89, 0.1);
  position: absolute;
  z-index: 2;
}

.jd-heading-bottom-light {
  width: auto;
  height: 2px;
  flex-shrink: 0;
  background: #d9d9d9;
  position: relative;
  margin-top: 1px;
}

.form-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.required {
  color: #ea1c1c;
}

.jdview-outer {
  background: #f7f8fa;
  padding: 50px 30px;
}

.jdview-section1 {
  background: #fff;
  border-radius: 16px;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
}

.jdview-section-profilepic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.jdview-section1-b {
  background: #f7f8fa;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
}

.jdview-section1-b-a {
  padding: 40px 3vw;
}

.jdview-section1-b-heading1 {
  color: #707070;
}

.jdview-section1-b-heading2 {
  color: #2576bc;
}

.jdview-seaction1-b-button {
  padding: 17px 54px 18px 55px;
  border-radius: 10px;
  background: #2576bc;
  color: #fff;
}

.jdview-seaction1-b-button:hover {
  box-shadow: 3px 5px 11px #0283f5;
  transition-duration: 0.5s;
}

.jdview-section2 {
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 16px;
  padding: 30px 4vw;
}

.jdview-section2-a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jdview-section2-ba {
  border-radius: 5px;
  background: rgba(37, 118, 188, 0.1);
}

.jdview-section2-b {
  padding: 7px 10px;
  border-radius: 5px;
  background: rgba(37, 118, 188, 0.1);
}

.jdview-section2-c {
  color: #2576bc;
  line-height: 28px;
  padding-left: 10px;
}

.jdview-section3-d {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 30px;
}

.section-d3 {
  border-radius: 15px;
  background: #f7f8fa;
  padding: 20px 2vw;
  margin: 9px;
  min-width: 400px;
}

.section-d6 {
  height: max-content;
  border-radius: 15px;
  padding: 20px 3vw;
  margin: 9px;
}

.section-d2 {
  width: 23vw;
  height: max-content;
  min-height: 230px;
  border-radius: 15px;
  background: #f7f8fa;
  padding: 20px 3vw;
  margin: 9px;
}

.section-d2c {
  width: 23vw;
  height: max-content;
  border-radius: 15px;
  background: #f7f8fa;
  padding: 20px 3vw;
  margin: 9px;
}

.section-d2b {
  height: max-content;
  border-radius: 15px;
  background: #f7f8fa;
  padding: 20px;
}

.section-d2a {
  min-height: 230px;
  border-radius: 15px;
  background: #f7f8fa;
  padding: 20px 3vw;
  margin: 9px;
}

.personal-info {
  text-align: left;
  color: #707070;
}

.user_img_display {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.personal-0 {
  color: #707070;
}

.info-heading {
  color: #000;
  font-style: normal;
  line-height: nor mal;
}

.personal-info-1 {
  padding: 14px;
  padding-left: 30px;
  color: #2576bc;
}

.section-address {
  height: max-content;
  width: 22vw;
  border-radius: 10px;
  padding: 9px;
  text-align: left;
  padding: 5vh;
}

.add-1-heading {
  color: #707070;
  padding-bottom: 10px;
}

.padding-bottom-5px {
  padding-bottom: 5px !important;
}

.jdview-note {
  padding: 20px 3vw;
  text-align: left;
  background: #f7f8fa;
  border-radius: 10px;
}

.note-1 {
  color: #707070;
}

.note-2 {
  color: #2576bc;
}

.jdview-section3 {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  padding: 30px;
}

.jdview-section2-d {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 30px;
}

.jdview-section2-da {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.jdview-section2-d-custom {
  display: flex;
  justify-content: center;
  height: max-content;
  border-radius: 10px;
  width: 73vw;
  background: #f7f8fa;
}

.jdview-section4 {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  padding: 30px;
}

.jdview-section5 {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  padding: 30px;
}

/* ******************************************************Interview********************************************************* */

.jdview-section2-e {
  width: 100%;
  display: flex;
  padding-top: 30px;
}

/* ******************* personal details ******************** */

.main-div-personaldetial {
  background-color: #f7f8fa;
}

.info-box-div-bg {
  background: #fff;
  padding: 5px 2vw 20px 3vw;
  border-radius: 16px;
}

.info-box-div-without-bg {
  /* background: #fff; */
  /* padding: 5px 2vw 20px 3vw; */
  border-radius: 16px;
}

.info-box-div-head {
  justify-content: space-between;
}

.info-box-div {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 16px;
  /* padding: 30px 2vw 20px 3vw; */
  margin: 0 0 10px 0;
}

.presonal-info {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 16px;
  background: #fff;
  padding: 30px 2vw 20px 3vw;
  margin: 0 0 10px 0;
}

.personal-detail-heading {
  color: #2576bc;
}
.tableflex {
  display: flex;
  justify-content: space-between;
}
.personal-detail-1 {
  color: #707070;
}

.personal-detail-2 {
  color: #101010;
  padding: 20px;
  padding-left: 55px;
}

.address-details {
  border-radius: 16px;
  background: #fff;
  margin: 0 0 10px 0;
  padding: 30px 10vw 20px 3vw;
}

.address-details-1 {
  display: flex;
  flex-wrap: wrap;
}

.current-address-margin {
  margin-right: 10vw;
}

.current-address h5 {
  color: #707070;
  font-size: 1em;
  font-weight: 400;
}

.current-address p {
  color: #101010;
  font-size: 0.85em;
  font-weight: 500;
}

.Education-details {
  border-radius: 16px;
  background: #fff;
  margin: 0 0 10px 0;
  padding: 20px 40px;
}

.education-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
}

.education-info-1 {
  margin-bottom: 20px;
}

.experiance-details {
  color: #000;
}

/* ******************* Job details ******************** */

.employeee-time {
  padding: 0px 60px 0px 0px;
}

/* ************************************************************* */

.jdfinal-section2 {
  width: 100%;
  height: auto;
  margin-top: 20px;
  align-items: center;
  border-radius: 20px;
  background: #fff;
  flex-direction: column;
}

.jdfinal-section2-a {
  margin-bottom: 25px;
}

.jdfinal-section2-a-a {
  display: flex;
  align-items: center;
  padding-left: 40%;
}

.jdfinal-section2-a-b {
  border-radius: 15px;
  background: rgba(37, 118, 188, 0.1);
  width: 48px;
  height: 48px;
}

.jdfinal-section2-a-c {
  background: #feefee;
  margin: 12px;
}

.jdfinal-section2-a-d {
  text-align: center;
  color: #2576bc;
  margin-left: 12px;
}

.jdfinal-sub-heading {
  color: #000;
  font-style: normal;
  line-height: 62px;
  letter-spacing: -0.56px;
}

.jdfinal-section2-c-aa {
  width: auto;
  display: flex;
  text-align: left;
  justify-content: flex-start;
}

/* .jdheading {
  color: #a3a3a3;
  font-size: 1.18em;
  font-weight: 500;
  padding-left: 20px;
} */

.jd-heading {
  width: 33rem;
  color: #101010;
  margin-top: 13px;
}

.user_main {
  background-color: #ffffff;
  border-radius: 16px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.user_main-outer {
  background-color: #f7f8fa;
  padding: 20px 20px 0px 20px;
}

.user_inner {
  margin: 20px 40px;
  background-color: #f7f8fa;
  border-radius: 10px;
  display: flex;
  /* width: 90vw; */
  justify-content: flex-start;
  flex-wrap: wrap;
}

.user_img_trial {
  position: absolute;
  width: 200px;
  height: 185px;
  border-radius: 10px;
}

.user_img {
  position: absolute;
  width: 200px;
  height: 210px;
  border-radius: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.profile_img {
  background-color: red;
  height: 100px;
  border-radius: 50px;
}

.selected_profile {
  height: 100px;
  border-radius: 10px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-color: blue;
  border-width: 50px;
}

.selectable_profile {
  border-color: rgb(255, 238, 5);
  border-width: 3px;
  cursor: pointer;
}

.user_img_box {
  border-radius: 0px 0px 10px 10px;
  background: rgba(37, 118, 188, 0.63);
  color: #ffffff;
  text-align: center;
  align-items: center;
  opacity: 0.9;
  margin-top: 178px;
  width: 200px;
  height: 32px;
}

.user_img_boxx {
  border-radius: 0px 0px 10px 10px;
  background: rgba(37, 118, 188, 0.63);
  color: #ffffff;
  text-align: center;
  align-items: center;
  opacity: 0.9;
  margin-top: 153px;
  width: 200px;
  height: 32px;
  /* height: auto; */
  overflow: auto;
}

.user_img_boxxx {
  border-radius: 0px 0px 10px 10px;
  background: rgba(37, 118, 188, 0.63);
  color: #ffffff;
  text-align: center;
  align-items: center;
  opacity: 0.9;
  margin-top: 178px;
  width: 200px;
  height: 32px;
}

.user_del {
  display: flex;
  flex-direction: column;
  margin: 30px 0 0 30px;
}

.user_name {
  color: #2576bc;
}

.user_inner_del {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #707070;
}

.user_inner_box {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.user_inner_box p {
  margin: 0 0 0 10px;
}

.user_inner2 {
  display: flex;
  justify-content: space-between;
  padding: 40px 50px 0 50px;
  flex-wrap: wrap;
}

.user_inner2 h1 {
  color: #707070;
}

.jdfinal-section2-b {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/******************************** Payment Dashboard******************************************************/
.bank-details,
.payrol-history,
.payment-status {
  padding: 10px 10px;
  border-radius: 10px;
  background-color: #fff;
}

.float-right {
  float: right !important;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title h2 {
  margin: 0;
  font-size: var(--subheading);
}

h2 {
  margin: 20px 0px 10px 0px !important;
  font-size: 24px;
  text-align: left;
}

p {
  margin-bottom: 10px;
  font-size: 16px;
}

.title span {
  color: #888;
  font-size: var(--text);
}

.status-bars {
  margin: 16px 0;
}

.status-bar {
  display: flex;
  height: 10px;
  background-color: #f0f0f0;
  overflow: hidden;
  width: 50%;
}

.fill {
  height: 100%;
}

.payment {
  background-color: #ff8a80;
}

.pending {
  background-color: #ffca28;
}

.paid {
  background-color: #66bb6a;
}

.payment-radius {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.payment-radius-b {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.status-labels {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}

.label {
  display: flex;
  align-items: center;
}

.label .dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 4px;
}

.payment .dot {
  background-color: #ff8a80;
}

.pending .dot {
  background-color: #ffca28;
}

.paid .dot {
  background-color: #66bb6a;
}

.label strong {
  margin-left: 2px;
}

.bank-details {
  margin-top: 20px;
}

.PaymentDashBoard {
  width: 100%;
  display: "flex";
}

.bank-label {
  justify-content: center;
}

.summaryCard {
  min-width: 200px;
  margin-top: 10px;
}

.summaryCardd {
  height: 70%;
  margin: 10px 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.summaryStats {
  display: flex;
  flex-wrap: wrap;
  width: 99%;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
}

.summaryCard-buttonn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.summaryCard-button {
  height: 37%;
  border-radius: 5px;
}

.Color-tag-active {
  background-color: #2576bc30;
  border: 1px solid #2576bccc;
  border-radius: 5px;
  color: #2576bccc;
  padding: 2px 5px;
}

.Color-tag-inactive {
  background-color: #ff747c30;
  border: 1px solid #ff747ccc;
  border-radius: 5px;
  color: #ff747ccc;
  padding: 2px 5px;
}

.summaryCard-number {
  margin: 0;
  font-weight: bold;
}

.summaryCard-text-b {
  color: #007bff;
}

.paymentDashboard {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5vw;
  min-width: 225px;
  justify-content: center;
}

.title {
  min-width: 129px;
}

.title p {
  margin: 0px;
  font-size: var(--text);
  padding: 0px;
  font-weight: bold;
}

.paymentDashboard-a {
  border: none;
}

.customLegend {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 20px;
  justify-content: center;
}

.payrollHistoryBox {
  width: 49vw;
  min-width: 225px;
}

.bank-details-header {
  display: flex;
  justify-content: space-between;
}

/* Spinner.css */
.spinner {
  border: 0.5em solid #f6f7f9;
  /* Light grey */
  border-top: 0.5em solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 3.75em;
  height: 3.75em;
  top: 50%;
  left: 50%;
  animation: spin 2s linear infinite;
}

.spinner-small {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.375em solid transparent;
  /* Transparent */
  border-top: 0.375em solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 1.875em;
  height: 1.875em;
  animation: spin 2s linear infinite;
}

.spinner-vsmall {
  border: 0.25em solid #f6f7f9;
  /* Transparent */
  border-top: 0.25em solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 0.75em;
  height: 0.75em;
  top: 50%;
  left: 50%;
  animation: spin 2s linear infinite;
}

.spinner-z {
  /* border: 0.5em solid transparent; */
  /* Transparent */
  border: 0.5em solid #f6f7f9;
  border-top: 0.5em solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 3.75em;
  height: 3.75em;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* RefreshButton.css */
.refresh-button {
  width: 2em;
  height: 2em;
  background-color: #3498db;
  /* Blue */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.refresh-icon {
  width: 1em;
  height: 1em;
  fill: #fff;
  /* White */
}

.refresh-button:hover {
  background-color: #000000;
  /* Yellow (or any other color you prefer for hover) */
}

.refresh-icon:hover {
  fill: #3498db;
  /* Yellow (or any other color you prefer for hover) */
}

.file-container {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file-container button {
  display: inline-block;
  padding: 8px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 4px;
}

#fileName {
  margin-left: 10px;
}

/* ?? *************************Print Slip CSS Start*************************************/

@media print {
  .hide-on-print {
    display: none !important;
  }
  .show-on-print {
    border: 2px solid #dddddddd !important;
  }

  .print-width {
    width: 50% !important;
  }
  .table-css-background {
    width: 90%;
  }

  table {
    font-size: 0.6dmeoem; /* Adjust as needed */
  }

  .print-center {
    display: flex;
    justify-content: center;
  }
}

.printSlip-bg {
  /* z-index: -1; */
  top: 200px;
  top: 100px;
  left: 0px;
  width: 745px;
  height: 650.939px;
  transform: rotate(-13.776deg);
  flex-shrink: 0;
  opacity: 0.1;
  background: url("../public/images/frame-10000027082.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  pointer-events: none;
}
.printSlip-container {
  min-height: 1155px;
}
.printSlip {
  width: 795px;
  height: 1125px;
  flex-shrink: 0;
  background: rgba(255, 252, 252, 0.95);
  position: absolute;
  padding: 10px;
}
.printSlip-width {
  width: 795px;
}

.printSlip-height {
  height: 1125px;
}

.printSlip-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.printSlip-header-left-text {
  color: #2e72b9;
  padding: 10px 25px;
  position: absolute;
  width: 430px;
}

.printSlip-logo {
  width: 200px;
  height: 100px;
  flex-shrink: 0;
  padding-top: 10px;
}

.printSlip-header-right-top {
  color: #8f3134;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  z-index: 1;
  width: 440px;
  margin-bottom: 15px;
}

.printSlip-header-right-bottom {
  text-align: right;
  color: #5a5656;
  line-height: normal;
  letter-spacing: -0.2px;
}

.text-center {
  text-align: center !important;
}

.printSlip-hr {
  width: 795px;
  height: 1px;
  background: linear-gradient(
    270deg,
    rgba(152, 148, 148, 0) 0%,
    #989494 46.8%,
    rgba(152, 148, 148, 0) 100%
  );
  margin-top: 20px;
}

.printSlip-header-second {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.printSlip-heading-black {
  color: #000;
  line-height: normal;
}

.printSlip-heading-black1 {
  color: #000;
  line-height: normal;
}

.printSlip-header-second-section3 {
  width: 1px;
  height: 70px;
  background: linear-gradient(
    180deg,
    rgba(152, 148, 148, 0) 0%,
    #989494 46.8%,
    rgba(152, 148, 148, 0) 100%
  );
}

.printSlip-header-second-section1 {
}
.printSlip-header-second-section2 {
}
.printSlip-header-second-section4 {
}
.printSlip-header-second-section5 {
}

.printSlip-body {
  padding: 0px 15px;
}

.printSlip-heading-blue {
  color: #2d72b8;
}

.printSlip-table {
}

.printSlip-head {
  background: #0089ff33;
  height: 40px;
}

.printSlip-table-td {
  text-align: center;
  padding: 5px 15px;
}

.bill-img {
  width: 35vw;
  border-radius: 20px;
}

.printSlip-hrr {
  width: 795px;
  height: 0.5px;
  background: linear-gradient(
    270deg,
    rgba(152, 148, 148, 0) 0%,
    #989494 46.8%,
    rgba(152, 148, 148, 0) 100%
  );
  margin-top: 20px;
}

.margin-top-65vh {
  margin-top: 65vh;
}

.button-margin {
  margin: 20px 0px;
}

.slip-container {
  height: 200vh;
  background-color: #f4f7fe;
}

/* !! *************************Print Slip CSS End*************************************/

/*********************! Media Tag START !*************************/

/*********************? Screen View ?*************************/

@media screen and (max-width: 768px) {
  /* #### Login ### */

  .login-page {
    flex-direction: column;
  }

  .login-page-img {
    width: 50vw;
  }

  .login-page-right {
    width: 70vw;
  }

  .form-input {
    width: 65vw;
    height: 50px;
  }

  .textarea {
    height: 20vh !important;
    width: 65vw;
  }

  .form-input-textarea {
    width: 65vw;
    height: 30vh !important;
    /* height: 100px; */
  }

  /* .form-input-check {
    width: 10vw;
    height: 25px;
  } */

  .form-group-selection {
    width: 65vw;
    height: 50px;
  }

  .login-button {
    width: 65.5vw;
  }

  .navbar-section-main {
    align-items: center;
  }

  .navbar-margin-right {
    align-items: center;
  }

  .navbar-subsection-b {
    margin-top: 10px;
    align-items: center;
  }

  .navbar-input {
    width: 100%;
  }

  .navbar-svg {
    margin: 5px;
  }

  .navbar-user-login {
    width: 35px;
    height: 35px;
  }

  .attendance-calendar-container {
    width: 95vw;
  }

  .calendar {
    width: 90vw;
  }

  .table-calander {
    width: 95vw;
  }

  .attendance-calander-heading-td {
    padding: 5px;
  }

  .piechart-flex {
    justify-content: center;
  }

  .piechart-dashboard {
    width: 45%;
    margin: 10px 0;
  }

  .piechartt {
    width: 90vw;
    min-width: 0;
    padding: 10px;
  }

  .summaryStats {
    justify-content: space-around;
  }

  .paymentDashboard {
    flex-direction: column;
  }

  .payrollHistoryBox {
    width: 100%;
    min-width: 225px;
  }

  .paymentDashboard-b {
    min-width: 225px;
  }
}

/*********************? Tablet View ?*************************/

@media only screen and (max-width: 720px) {
  .hrdb-piechart-dashboard {
    width: 55vw;
  }

  .balance-cont {
    width: 55vw;
    /* height: 500px; */
  }

  .customLegend {
    justify-content: flex-start;
  }
}

/*********************? MObile View ?*************************/

@media screen and (max-width: 480px) {
  .custom-modal {
    /* width: 40vw !important; */
    max-width: 40vw !important;
    min-width: 95vw !important;
  }

  .ourcompany-model {
    /* width: 80vw !important; */
    max-width: 80vw !important;
    min-width: 100vw !important;
    /* background-color: #000 !important; */
  }

  .request-leave {
    width: 40vw !important;
    max-width: 40vw !important;
    min-width: 95vw !important;
  }

  .user_inner {
    margin: 10px 20px;
    width: 70vw;
  }

  .navbar-section-main {
    margin: 0 0 10px 0;
  }

  .navbar-subheading {
    font-size: var(--label) !important;
  }

  .navbar-subsection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .applymodel {
    margin: 0 10px;
  }

  .model-button-leave {
    font-size: 0.85em !important;
    padding: 10px 22px !important;
  }

  .navbar-section-main {
    padding: 5px;
  }

  .navbar-logo-mobile {
    gap: 20px;
  }

  .navbar-svg {
    padding: 5px;
  }

  .attendance-calendar-container {
    margin: 0 0 10px 0;
  }

  .attendance-calander-heading,
  .calander-height,
  .attendance-calander-span {
    font-size: var(--heading) !important;
  }

  .piechartt {
    margin: 0 0 10px 0;
  }

  .piechart-dashboard {
    align-items: center !important;
  }

  .piechart-headings,
  .piechart-headings {
    margin: 5px;
  }

  .piechart-heading {
    font-size: var(--heading) !important;
  }

  .piechart-flex {
    justify-content: center;
  }

  .piechart-dashboard {
    width: 90%;
    margin: 5px 0;
  }

  .piechartt {
    width: 67vw;
    padding: 5px;
  }

  .piechart-heading {
    width: 130px;
  }

  .piechart-percentage {
    font-size: var(--label);
    width: 130px;
  }

  .bloc-tabs-apply4 {
    padding: 0 5px !important;
    gap: 5px;
  }

  .model-button {
    padding: 8px 25px !important;
    font-size: 0.85em !important;
  }

  .Clockin-Webcam {
    width: 280px;
    height: 200px;
    margin: 0 0 10px 0;
  }

  .piechart-flex2 {
    width: 90%;
  }

  .dashcomp-image {
    margin: 0 0 5px 0;
  }

  .backgroundmain {
    margin: 0 0 10px 0 !important;
  }

  .dashboard-designs-right-flex {
    width: 83% !important;
  }

  .dashboardcomponent-task {
    width: 100% !important;
  }

  .dashboard-designs-secondary-flex {
    margin: 5px 0 0 0 !important;
  }

  .dashboard-main-content-sectionnn {
    width: calc(100vw - 60px);
  }

  .sidebar-closed-logo-cipl {
    height: 30px;
    width: 50px;
    cursor: pointer;
    margin-top: 10px;
  }

  .backgroundmain-task {
    margin: 0 0 10px 0 !important;
  }

  .navbar-section-main {
    padding: 20px 0;
  }

  .navbar-margin-right {
    flex-direction: column;
    align-items: start;
  }

  .navbar-subsection-b {
    width: 85%;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .navbar-search {
    width: 85%;
    align-items: center;
  }

  .navbar-input {
    width: 100%;
    margin: 5px 0;
  }

  .navbar-svg {
    margin: 5px;
  }

  .navbar-user-login {
    width: 30px;
    height: 30px;
  }

  .dashboardcomponent {
    width: 63vw;
  }

  .dashboardcomponent-task {
    min-width: 200px;
  }

  .attendance-calendar-container {
    width: 90vw;
    padding: 10px;
    zoom: 75%;
  }

  .calendar {
    width: 85vw;
  }

  .table-calander {
    width: 90vw;
    zoom: 70%;
  }

  .attendance-calander-heading {
    font-size: var(--label);
  }

  .attendance-calander-heading-td {
    padding: 3px;
  }

  .attendance-calander-span {
    font-size: var(--label);
  }

  .status-bar {
    width: 150px;
  }
}

/* ********************! Media Tag END !************************ */

/* ********************! ConsultantInvoice start !*************************************************** */

.consultant-heading {
  margin: 10px 30px;
}

.consultant-headings {
  margin: 10px 0;
}

.border-none {
  border: none !important;
}

.table-css-backgrounds {
  background: #ffffff;
  padding: 15px;
  overflow: scroll;
  margin: 10px;
  border: #dddddddd;
  border-radius: 15px;
  /* border: 1px solid; */
}

.heading-conslt {
  display: flex;
  justify-content: space-between;
}

.invoice-heading {
  text-align: center;
  text-decoration: underline;
}

.total-amount td {
  font-weight: bold;
}

.invoice-table-container {
  padding: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  text-align: left;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.invoice-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.invoice-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.invoice-table tbody tr:hover {
  background-color: #f1f1f1;
}

@media (max-width: 768px) {
  .invoice-table th,
  .invoice-table td {
    padding: 6px;
    font-size: 12px;
  }
}

.Key-pro {
  background-color: #4db4f03e;
}

/* styles.css */

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.progress-bar-fill {
  height: 10px;
  background-color: #76c7c0;
  border-radius: 4px;
  transition: width 0.2s;
}

.progress-bar span {
  position: absolute;
  right: 10px;
  top: -25px;
  font-size: 12px;
  color: #333;
}

.eot-dashboard-container {
  width: 100%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 20px auto;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.dashboard-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.status-dropdown {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
}

.eot-list {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e8e8e8;
}

.eot-item {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}

.eot-header {
  display: flex;
  align-items: left;
  gap: 8px;
  margin-bottom: 8px;
  border-top: 1px solid #e8e8e8;
}

.eot-code {
  background: #e0e7ff;
  color: #3b82f6;
  padding: 10px 15px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
}

.eot-project-name {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 10px 0px 0px 10px;
}

.eot-details {
  font-size: 14px;
  color: #666;
}

.contractor-info {
  font-weight: 500;
}

.days-left {
  font-weight: 600;
}

.approval-date,
.letter-number,
.process-initiate-date {
  color: #3b82f6;
  font-weight: 500;
}

.approval-date {
  margin-top: 20px;
}

.eot-details p {
  margin: 4px 0;
}

.letter-summary-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.summary-heading {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 16px;
}

.summary-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.summary-table th,
.summary-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
  font-weight: 500;
  color: #333;
}

.summary-table th {
  background: #f5f5f5;
  color: #333;
  font-weight: 600;
}

.summary-table td {
  background: #fff;
  color: #666;
}

.letter-summary {
  margin-top: 16px;
}

.summary-table td {
  background: #fff;
  color: #666;
}

.summary-table td:first-child {
  font-weight: bold;
  color: #333;
}

.status-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: red; /* Default for Contractor */
}

.status-icon.consultant {
  background-color: green; /* For Consultant */
}

/* *******? Apply Button new css START ********* */

.leave-balance-container {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
}

.leave-balance-heading {
  font-size: 1.4rem;
  /* font-weight: bold; */
  color: #333;
  text-align: center;
  margin-bottom: 12px;
}

/* Card Container */
.leave-balance-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

/* Card Styles */
.leave-balance-card {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.leave-balance-card:hover {
  transform: translateY(-3px);
}

.leave-balance-info {
  margin-left: 8px;
}

/* Icon Styles */
.leave-balance-icon {
  font-size: 1.6rem;
}

/* Color Coding for Each Card Type */
.leave-balance-card-previous {
  border-left: 3px solid #2196f3;
}

.leave-balance-card-earned {
  border-left: 3px solid #ff9800;
}

.leave-balance-card-casual {
  border-left: 3px solid #4caf50;
}

.leave-balance-card-sick {
  border-left: 3px solid #f44336;
}

/* Container and Heading */
.leave-duration-container {
  width: 100%;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
/* 
.leave-duration-heading {
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
} */

/* Radio Group */
.leave-radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

/* Radio Option */
.leave-radio-option {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s ease;
  cursor: pointer;
}

.leave-radio-option:hover {
  border-color: #007bff;
}

.leave-radio-input {
  margin-right: 8px;
  accent-color: #007bff;
}

.leave-radio-label {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
}

/* *******! Apply Button new css break1 !********* */

.navbar-section-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0px;
}

.navbar-margin-left {
  margin-left: 20px;
}

.navbar-margin-right {
  margin-right: 10px;
}

.navbar-subheading {
  color: #7f7171;
  line-height: 24px;
}

.navbar-heading {
  color: #2576bc;
  font-size: 1.87em;
  line-height: 42px;
  letter-spacing: -0.6px;
}

.navbar-subsection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.navbar-subsection-b {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0px 10px;
  background-color: #fff;
  border-radius: 30px;
}

/* .navbar-search {
  display: flex;
  align-items: center;
  background-color: #f4f7fe;
  border-radius: 30px;
  padding: 5px 15px;
  height: 40px;
} */

/* Style for the main search container */
.navbar-search {
  position: relative; /* Needed for positioning the dropdown */
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #f4f7fe;
  border-radius: 30px;
  padding: 5px 15px;
  height: 40px;
}

/* .navbar-input {
  padding: 10px;
  width: 200px;
  border-radius: 4px;
  outline: none;
  margin-left: 10px;
  font-size: 14px;
} */

/* Suggestions dropdown styles */
.search-suggestions-popup {
  position: absolute;
  top: 100px; /* Position directly below the input field */
  /* left: 0; */
  width: 100%; /* Match the width of the input */
  max-height: 200px;
  overflow-y: auto; /* Allow scrolling if there are too many suggestions */
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 999; /* Ensure it appears above other content */
  padding: 5px 0;
}

/* Style for each suggestion item */
.search-suggestion-item {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
}

.search-suggestion-item:hover {
  background-color: #f5f5f5;
}

/* Style for when there are no results */
.no-results {
  color: #888;
  text-align: center;
  padding: 10px 15px;
}

/* Optional: Add transition effects to the suggestion items */
.search-suggestion-item {
  transition: background-color 0.2s ease-in-out;
}

.logbook-container {
  width: 95%;
  margin: 20px auto;
  font-family: "Arial", sans-serif;
}

.logbook-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  font-weight: bold;
  text-decoration: underline;
}

.header-item {
  font-size: 16px;
  font-weight: bold;
}
.header-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logbook-table {
  width: 100%;
  border-collapse: collapse;
}

.logbook-table th,
.logbook-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

.logbook-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.logbook-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.logbook-table td {
  font-size: 14px;
  white-space: nowrap;
}

@media print {
  .logbook-container {
    width: 100%;
    margin: 0;
  }
}

/* Container styling */
.breakdown-container {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.table-css-background {
  background: #fff;
  padding: 15px;
  overflow: scroll;
  margin: 10px;
  /* border: 1px solid #dddddddd; */
}

/* Table styling */
.breakdown-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

/* Header styling */
.breakdown-table thead th {
  background-color: #f2f2f2;
  padding: 8px;
  text-align: left;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
}

/* Row and cell styling */
.breakdown-table tbody td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Alternate row color */
.breakdown-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Highlight the first column (S.No) */
.breakdown-table tbody td:first-child {
  font-weight: bold;
}

/* Align the number and amount columns to the right */
.breakdown-table tbody td:nth-child(4),
.breakdown-table tbody td:nth-child(5),
.breakdown-table tbody td:nth-child(6) {
  text-align: right;
}

/* Adjusting the table headers to be slightly larger */
.breakdown-table thead th {
  font-size: 1.1em;
}
.del-button {
  background-color: #fff;
}

/* Bold and slightly larger headings */
.heading-div {
  display: flex;
}

.heads-div {
  justify-content: space-between;
  display: flex;
}
.main-heading {
  width: 100%;
  font-size: 1.5em;
  color: #333;
  text-align: center;
}

/* .parent-div{
    display: flex;
} */

.nested-width {
  width: 10vw;
  text-align: center;
}

.pre-heading {
  font-weight: bold;
}

.breakdown-heading {
  width: 220%;
  font-size: 1.5em;
  color: #333;
  margin-left: 150%;
}

.cost-estimate-container {
  width: 80%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.staff-table,
.transport-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.staff-table th,
.staff-table td,
.transport-table th,
.transport-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.staff-table th,
.transport-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.staff-table tbody tr:nth-child(even),
.transport-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.duty-travel-container {
  width: 80%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.duty-travel-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.duty-travel-table th,
.duty-travel-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.duty-travel-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.duty-travel-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.total-label {
  text-align: right;
  font-weight: bold;
}

.note {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}

.office-rent-container {
  width: 70%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.office-rent-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.office-rent-table th,
.office-rent-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.office-rent-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.office-rent-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.total-label {
  text-align: right;
  font-weight: bold;
}
.office-supplies-container {
  width: 80%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.office-supplies-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.office-supplies-table th,
.office-supplies-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.office-supplies-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.office-supplies-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.total-label {
  text-align: right;
  font-weight: bold;
}
.office-furniture-container {
  width: 90%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.office-furniture-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.office-furniture-table th,
.office-furniture-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.office-furniture-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.office-furniture-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.total-label {
  text-align: right;
  font-weight: bold;
}
.reports-printing-container {
  width: 90%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.reports-printing-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.reports-printing-table th,
.reports-printing-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.reports-printing-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.reports-printing-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.total-label {
  text-align: right;
  font-weight: bold;
}
.road-survey-container {
  width: 90%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.road-survey-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.road-survey-table th,
.road-survey-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.road-survey-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.road-survey-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.total-label {
  text-align: right;
  font-weight: bold;
}

.note {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}
.contingencies-container {
  width: 80%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.contingencies-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.contingencies-table th,
.contingencies-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.contingencies-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.contingencies-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.total-label {
  text-align: right;
  font-weight: bold;
}

.balance-chart-cont,
.bgwhite {
  background: #fff;
}
.cards-shadow2 {
  box-shadow: 0 8px 24px 0 #8c959f0d;
}
.cont-col {
  display: flex;
  flex-direction: column;
  min-width: 165px;
}
.invoice-cont {
  border-radius: 16px;
  /* height: 45vh; */
  padding: 10px 20px;
}

.height-44vh {
  height: 44vh !important;
}

.heading {
  position: sticky;
  top: 0;
  z-index: 1000; /* Make sure it stays above other content */
  background-color: white; /* Keep the background color when scrolling */
  padding: 10px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); Add a shadow to distinguish the header */
}

.width-43vw {
  width: 44vw !important;
}
.margin-20px {
  margin: 0px 0px 20px 0px;
}

.justify-between {
  justify-content: space-between !important;
}
.justify-evenly {
  justify-content: space-evenly !important;
}

.global-table-cont {
  height: 100%;
  overflow-y: scroll;
  max-height: 311px;
}

.custom-table {
  background-color: #fff;
  border-collapse: collapse;
  caption-side: top;
  /* overflow: hidden; */
  padding: 20px;
  width: 100%;
  border-radius: 16px;
}

.custom-table-head-tr > th {
  padding: 30px 25px;
  color: #707070;
  font-size: var(--label);
  font-weight: var(--fw400);
}

.tr-border-bottom {
  background-color: #e8e8e8;
  margin: 0 10px;
}

.repo-heading {
  color: #2576bc;
  margin-bottom: 20px;
  /* padding-left: 20px; */
}

.dashboard-card-heading-blue {
  color: #2576bc;
  margin-bottom: 20px !important;

  font-size: 1.12em;
  font-weight: 500;
}

.headingcolor {
  color: #2576bc;
}

.text-red {
  color: red !important;
}
.text-green {
  color: green !important;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}
.custom-table-head-trr > th {
  padding: 30px 20px;
  font-size: 0.7em;
  color: #707070;
}

.custom-table-head-td > td {
  border-top: 1px solid #e8e8e8;
  color: #101010;
  padding: 20px 25px;
  position: relative;
}
.align-left {
  color: #707070;
  text-align: left;
  width: 50px;
}
.font-weight400 {
  font-weight: 400;
  font-weight: var(--fw400);
}
.font-size-text {
  font-size: 0.85em;
  font-size: var(--text);
}
.font-weightbold {
  font-weight: bold !important;
}

.dashboard-container {
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.box-shadow {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.dashboard-title {
  text-align: center;
  color: #333;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.chart-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.chart-container {
  background-color: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  margin: 15px;
  flex: 1;
}

.height-45vh {
  height: 44vh !important;
}

.chart-title {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 15px;
}

.statistics-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.statistics-list li {
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.statistics-list li:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pie-chart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stat-cards {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  /* background-color: #faf9f9;  */
  border-radius: 16px;
  height: 150px;
  padding: 10px 20px;
}

.stat-card {
  flex: 1; /* Make each card equally wide */
  padding: 10px 20px;
  border-radius: 0px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #ddd; /* Add right border to create dividing line */
}

.stat-card:last-child {
  border-right: none; /* Remove the right border for the last card */
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.stat-label {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.stat-details {
  display: flex;
  justify-content: space-between;
}

.detail-item {
  text-align: center;
}

.detail-label {
  display: block;
  font-size: 12px;
  color: #888;
}

.detail-count {
  font-size: 14px;
  font-weight: bold;
}

/* Individual Tab Styling */
.leave-tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center; /* Center-align text horizontally */
  height: 30px;
  font-size: 0.9rem;
  /* font-weight: 500; */
  color: black;
  cursor: pointer;
  transition: color 0.15s ease-in;
  text-align: center;
  margin: 0; /* Remove any potential margin */
}

/* Tabs Container */
.leave-duration-tabs {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  background-color: #fff;
  /* box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15),
    0 6px 12px 0 rgba(24, 94, 224, 0.15); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  padding: 0.5rem; /* Reduce padding for better alignment */
  border-radius: 50px;
  overflow: hidden;
  width: 100%;
}

.leave-duration-tabs * {
  z-index: 2;
}

/* Hide Radio Inputs */
.leave-duration-tabs input[type="radio"] {
  display: none;
}

/* Glider for Sliding Animation */
.leave-tab-glider {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90%;
  width: 25%; /* Matches each tab's width */
  background-color: #e6eef9;
  z-index: 1;
  /* border: 1px solid #ccc; */

  border-radius: 99px;
  transition: transform 0.3s ease-out;
}

.glider-width-letter {
  width: 11% !important;
}

/* Positioning the Glider for Each Tab */
.leave-duration-tabs input[id="toggle1"]:checked ~ .leave-tab-glider {
  transform: translateX(0%);
}

.leave-duration-tabs input[id="toggle2"]:checked ~ .leave-tab-glider {
  transform: translateX(100%);
}

.leave-duration-tabs input[id="toggle3"]:checked ~ .leave-tab-glider {
  transform: translateX(200%);
}

.leave-duration-tabs input[id="toggle4"]:checked ~ .leave-tab-glider {
  transform: translateX(300%);
}
.leave-duration-tabs input[id="toggle5"]:checked ~ .leave-tab-glider {
  transform: translateX(400%);
}

.leave-duration-tabs input[id="toggle6"]:checked ~ .leave-tab-glider {
  transform: translateX(500%);
}

.leave-duration-tabs input[id="toggle7"]:checked ~ .leave-tab-glider {
  transform: translateX(600%);
}
.leave-duration-tabs input[id="toggle8"]:checked ~ .leave-tab-glider {
  transform: translateX(700%);
}
.leave-duration-tabs input[id="toggle9"]:checked ~ .leave-tab-glider {
  transform: translateX(800%);
}

.leave-duration-tabs input[id="toggle10"]:checked ~ .leave-tab-glider {
  transform: translateX(900%);
}

/* ********************? checkbox-toggle css Start ?************************ */

/* Checkbox Toggle Styling */
.checkbox-toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-top: 10px;
}

.checkbox-toggle input {
  display: none;
}

.checkbox-toggle label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.checkbox-toggle label::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.checkbox-toggle input:checked + label {
  background-color: #2576bc;
}

.checkbox-toggle input:checked + label::after {
  transform: translateX(20px);
}

.checkbox-toggle input:disabled + label {
  background-color: #ddd;
  cursor: not-allowed;
}

.checkbox-toggle input:disabled + label::after {
  background-color: #bdbdbd;
}

/* ********************! checkbox-toggle css END !************************ */

/* General Layout */

/* old css
 */

.reference-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: baseline;
  justify-content: space-between;
}

.reference-section {
  flex: 1;
  min-width: 200px;
}

.reference-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1rem;
  color: #222;
}

.arrow-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.arrow-list li {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  position: relative;
  padding-left: 20px; /* Space for the arrow icon */
}

.arrow-list li::before {
  content: "→"; /* Unicode arrow character */
  position: absolute;
  left: 0;
  color: #007bff; /* Highlight color for the arrow */
  font-size: 1rem;
}

.date-section {
  flex: 1;
  min-width: 200px;
}

.date-text {
  font-style: italic;
  color: #555;
  font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
  .reference-container {
    flex-direction: column;
    gap: 15px;
  }

  .arrow-list li {
    font-size: 0.9rem;
  }
}
.scrollbar {
  overflow: auto;
  max-height: 500px;
  max-width: 100%;
  border: 1px solid #ccc;
}

.scroll-container {
  overflow: auto;
  max-height: 500px;
  max-width: 100%;
  border: 1px solid #ccc;
  padding: 20px 20px 20px 20px;
  margin-top: 20px;
}

.scrollbar::-webkit-scrollbar {
  display: block;
  width: 12px;
  height: 12px;
}

/* Custom scrollbar track */
.scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f0f0f0;
}

/* Custom scrollbar thumb */
.scrollbar::-webkit-scrollbar-thumb {
  background: #f0f0f0;
  border-radius: 999px;
  border: 3px solid #f0f0f0;
  transition: background 0.3s ease;
}

/* Hover effect */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background: #2576bc;
}

/* Active effect */
.scrollbar::-webkit-scrollbar-thumb:active {
  background: #2576bc;
}

/* CSS to manage nested details visibility and spacing */
.custom-table-head-td {
  padding: 10px;
}

.custom-table-head-td .nested-row {
  display: none; /* Hide by default */
  padding-left: 20px; /* Add indentation for nested rows */
}

.custom-table-head-td.show-details .nested-row {
  display: table-row; /* Show nested rows when class is added */
}

/* Optional: Add a gap between rows */
.custom-table-head-td .nested-row + .nested-row {
  margin-top: 10px;
}

/* For View Details button */
.document-download-button {
  margin-bottom: 10px;
  height: 30px;
}

.table-heading-text {
  font-weight: bold;
}

.letter-card {
  background-color: #f9f9f9;
  padding: 10px 20px 20px 10px;
  border-radius: 8px;
  border-left: 6px solid #2476bd;
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s;
  max-width: 70vw;
  min-width: 35vw;
}

.letter-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.letter-card-header {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: space-between;
}

.arrow-set {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.arrow-icon {
  margin-right: 10px;
  font-size: 18px;
  color: #3f51b5;
  cursor: pointer;
}

.letter-toggle-btn {
  background-color: #3f51b5;
  color: white;
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 15px;
  border: none;
}

.nested-letters {
  padding-left: 20px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 768px) {
  .letter-card {
    padding: 15px;
  }
  .letter-toggle-btn {
    padding: 6px 12px;
  }
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* *******? new css : New View Employee Details : START ?********* */

.overlap-image-div {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}
.overlap-image-box {
  margin-left: -8px;
}
.outer-overlap-image-box {
  position: relative;
}
.overlap-img {
  border: 0.5px solid #2576bc;
}
.overlap-image-box:first-child(1) {
  margin-left: 0px;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-body {
  scrollbar-width: thin;
  scrollbar-color: #2576bc #f0f0f0;
}
.overlap-employee-details {
  position: absolute;
  top: -90px;
  right: -30px;
  border-radius: 4px;
  font-size: 0.85em;
  background: #2576bc;
  padding: 10px 20px 10px 20px;
  color: white;
  border: 1px solid #2576bc;
}

.overlap-employee-details::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: 40%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #2576bc;
}
.show-more-employee {
  border: 1px solid #e3e3e3;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
  margin-left: 10px;
  color: white;
  background-color: #2576bc;
}

.dots-container,
.add-more-container {
  display: flex;
  justify-content: center;
  gap: 2px;
  align-items: center;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #007bff;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.dot:hover {
  opacity: 1;
}

/* *******! new css : New View Employee Details : END !********* */
/* *******? new css : bar loader : START ********* */
.form-loader {
  width: 33vw;
  height: 50px;
  margin: 10px 0px 20px 0px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
}
.bar-loader {
  display: block;
  --height-of-loader: 20%;
  --loader-color: #0071e2;
  width: 100%;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: "green";
  position: relative;
}

.bar-loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 2s ease-in-out infinite;
}

@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}
/* *******! new css : bar loader : END !********* */

.fixed-nav {
  position: fixed;
  left: 12px;
  z-index: 100;
}

/* scrollbar css  */
.scrollbar {
  overflow: auto;
  max-height: 500px;
  max-width: 100%;
  border: 1px solid #ccc;
}

.scroll-container {
  overflow: auto;
  max-height: 500px;
  max-width: 100%;
  border: 1px solid #ccc;
  padding: 20px 20px 20px 20px;
  margin-top: 20px;
}

.scrollbar::-webkit-scrollbar {
  display: block;
  width: 12px;
  height: 12px;
}

/* Custom scrollbar track */
.scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f0f0f0;
}

/* Custom scrollbar thumb */
.scrollbar::-webkit-scrollbar-thumb {
  background: #f0f0f0;
  border-radius: 999px;
  border: 3px solid #f0f0f0;
  transition: background 0.3s ease;
}

/* Hover effect */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background: #2576bc;
}

/* Active effect */
.scrollbar::-webkit-scrollbar-thumb:active {
  background: #2576bc;
}

.scroll-container-table {
  overflow-x: auto !important;
  max-height: calc(100vh - 15vh) !important;
  max-width: 100%;
  min-height: 60vh;
  /* overflow-y:hidden; */
  position: relative;
}
.child-table-scroll {
  max-height: calc(100vh - 60vh) !important;
  min-height: 20vh;
}
.scrollbar-table {
  width: 100%;
  border-collapse: collapse;
  /* min-width: 600px; */
}

.scroll-container-table::-webkit-scrollbar {
  display: block;
  width: 0px;
  height: 12px;
}

.scroll-container-table::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f0f0f0;
}

.scroll-container-table::-webkit-scrollbar-thumb {
  background: #f0f0f0;
  border-radius: 999px;
  border: 3px solid #f0f0f0;
  transition: background 0.3s ease;
}

.scroll-container-table:hover::-webkit-scrollbar-thumb {
  background: #2576bc;
}

.scroll-container-table::-webkit-scrollbar-thumb:active {
  background: #2576bc;
}

.toggle-button-area {
  overflow: auto;
  display: flex;
  /* flex-wrap: wrap; */
  padding: 20px 2vw 20px 3vw;
  background: #ffffff;
  align-items: center;
  border-radius: 16px;
  margin: 10px 0px;
}

.text-lefttt {
  padding: 30px 0px 0px 0px !important;
}
.length-height-box {
  display: flex;
  justify-content: space-around;
  gap: 5px;
}
.select-box-parameter {
  margin-left: 10px;
}
.select-per-value {
  border: 1px solid black;
}

.max-70-width {
  max-width: 70vw;
}

/* .parent-child {
  background-color: yellow;
}
.parent {
  background-color: red;
}
.parent-child > td {
  padding: 20px 30px;
} */
/* .row-level-0 {
  background-color: #f9f9f9;
}

.row-level-1 {
  background-color: #e8e8e8;
  padding-left: 20px;
}

.row-level-2 {
  background-color: #dcdcdc;
  padding-left: 40px;
} */

.modal-body {
  scrollbar-width: thin;
  scrollbar-color: #2576bc #f0f0f0;
}

.modal-body::-webkit-scrollbar {
  height: 8px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #2576bc;
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.border-top-bottom {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.flexchooseunit {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  width: 34vw;
  padding-left: 0px;
}

.flexchooseunit .form-input {
  width: 50%;
}
